import React, { FC, SVGProps } from 'react';
import { COLOR_PRIMARY } from 'const';

const IconBook: FC<SVGProps<SVGSVGElement> & {
  isFilled?: boolean
}> = ({
  isFilled,
  color = COLOR_PRIMARY
}) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.50001 3H8.50001C9.56088 3 10.5783 3.42143 11.3284 4.17157C12.0786 4.92172 12.5 5.93913 12.5 7V21C12.5 20.2044 12.1839 19.4413 11.6213 18.8787C11.0587 18.3161 10.2957 18 9.50001 18H2.50001V3Z" fill={isFilled ? color : undefined} stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.5 3H16.5C15.4391 3 14.4217 3.42143 13.6716 4.17157C12.9214 4.92172 12.5 5.93913 12.5 7V21C12.5 20.2044 12.8161 19.4413 13.3787 18.8787C13.9413 18.3161 14.7044 18 15.5 18H22.5V3Z" fill={isFilled ? color : undefined} stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default IconBook;
