import { shallowEqual as _shallowEqual } from 'react-redux';
import {
  PROGRESS_LEVEL_ZERO, PROGRESS_LEVEL_LOW, PROGRESS_LEVEL_HIGH, PROGRESS_LEVEL_MEDIUM, MobileAppEventType
} from 'const';

/*
Usage:
Modify<OriginalType, {
  foo: number
  bar: string
}>
*/
export type Modify<T, R> = Omit<T, keyof R> & R;

export type Timedelta = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export function calcPertcent(value: number, maxValue: number): number {
  return Math.round(value / maxValue * 100);
}

export function calculateTimedelta(date1: Date, date2: Date): Timedelta {
  const difference = date1.getTime() - date2.getTime();
  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60)
  };
}

export function timedelta(
  params: { days?: number, hours?: number, minutes?: number, seconds?: number }
): Timedelta {
  return {
    days: params.days || 0,
    hours: params.hours || 0,
    minutes: params.minutes || 0,
    seconds: params.seconds || 0
  };
}

export function formatTimedeltaMinSec(timedelta: Timedelta): string {
  return `${String(timedelta.minutes).padStart(2, '0')}:${String(timedelta.seconds).padStart(2, '0')}`;
}

export function getLevelClassNameByPercent(percent: number): string {
  return {
    PROGRESS_LEVEL_ZERO: 'level-zero',
    PROGRESS_LEVEL_LOW: 'level-low',
    PROGRESS_LEVEL_MEDIUM: 'level-low', // 'level-medium',
    PROGRESS_LEVEL_HIGH: 'level-high'
  }[getProgressLevelByPercent(percent)];
}

type _ProgressLevel =
  | typeof PROGRESS_LEVEL_ZERO
  | typeof PROGRESS_LEVEL_LOW
  | typeof PROGRESS_LEVEL_MEDIUM
  | typeof PROGRESS_LEVEL_HIGH

export function getProgressLevelByPercent(percent: number): _ProgressLevel {
  if (percent == 0) {
    return PROGRESS_LEVEL_ZERO;
  } else if (percent < 30) {
    return PROGRESS_LEVEL_LOW;
  } else if (percent < 60) {
    return PROGRESS_LEVEL_MEDIUM;
  } else {
    return PROGRESS_LEVEL_HIGH;
  }
}

export function shuffle<T>(items: T[]): T[] {
  items = [...items];
  let currentIndex = items.length;
  while (currentIndex > 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    const value = items[currentIndex];
    items[currentIndex] = items[randomIndex];
    items[randomIndex] = value;
  }
  return items;
}

export function getRandomItems<T>(items: T[], count: number, exclude: T[]): T[] {
  let output: T[] = [];
  if (items.length <= count) {
    output = [...items];
  } else {
    const maxOptionsCount = Math.min(count, items.length);
    let allowedRetries = 3;
    while (output.length < maxOptionsCount && allowedRetries) {
      const index = Math.floor(Math.random() * items.length);
      const item = items[index];
      if (output.includes(item) || exclude.includes(item)) {
        allowedRetries--;
      } else {
        output.push(item);
      }
    }
  }
  return output;
}

export const findSameBeginning = (text1: string, text2: string): string => {
  let result = text1.toLowerCase();
  text2 = text2.toLowerCase();
  let index = Math.min(text1.length, text2.length);
  while (result.length) {
    if (text2.startsWith(result)) {
      return result;
    }
    index--;
    result = result.slice(0, index);
  }
  if (!result.length) {
    return text2;
  }
  return result;
};

export function capitalize(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function calcProgressPercent(value: number, targetValue: number): number {
  // (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed
  if (!value) {
    return 0;
  }
  const percent = Math.floor(value / targetValue * 100);
  return percent > 100 ? 100 : percent;
  // return Math.round((100 - 1) * (Math.log10(percent) - 0) / (2 - 0) + 1);
}

export const shallowEqual = <T>(a: T, b: T): boolean => _shallowEqual(a, b);

export function getNumberCaptionRU(value: number, pluralCations: string[]): string {
  /**
   * Склонает строку относительно числа
   * ['товар','товара','товаров']
   */
  if (pluralCations.length !== 3) {
    throw new Error();
  }
  const lastPart = Math.abs(Number(String(value).split('.').slice(-1)[0]));
  if ([11, 12, 13, 14].indexOf(lastPart % 100) === -1) {
    switch (lastPart % 10) {
      case 1: return pluralCations[0];
      case 2:
      case 3:
      case 4: return pluralCations[1];
    }
  }
  return pluralCations[2];
}

const IPHONE_MODEL_INDEX_BY_MACHINE_NAME: { [Key: string]: number } = {
  'iPhone1,1': 0, // 'iPhone',
  'iPhone1,2': 3, // 'iPhone 3G',
  'iPhone2,1': 3, // 'iPhone 3GS',
  'iPhone3,1': 4, // 'iPhone 4',
  'iPhone3,2': 4, // 'iPhone 4 GSM Rev A',
  'iPhone3,3': 4, // 'iPhone 4 CDMA',
  'iPhone4,1': 4, // 'iPhone 4S',
  'iPhone5,1': 5, // 'iPhone 5 (GSM)',
  'iPhone5,2': 5, // 'iPhone 5 (GSM+CDMA)',
  'iPhone5,3': 5, // 'iPhone 5C (GSM)',
  'iPhone5,4': 5, // 'iPhone 5C (Global)',
  'iPhone6,1': 5, // 'iPhone 5S (GSM)',
  'iPhone6,2': 5, // 'iPhone 5S (Global)',
  'iPhone7,1': 6, // 'iPhone 6 Plus',
  'iPhone7,2': 6, // 'iPhone 6',
  'iPhone8,1': 6, // 'iPhone 6s',
  'iPhone8,2': 6, // 'iPhone 6s Plus',
  'iPhone8,4': 5, // 'iPhone SE (GSM)',
  'iPhone9,1': 7, // 'iPhone 7',
  'iPhone9,2': 7, // 'iPhone 7 Plus',
  'iPhone9,3': 7, // 'iPhone 7',
  'iPhone9,4': 7, // 'iPhone 7 Plus',
  'iPhone10,1': 8, // 'iPhone 8',
  'iPhone10,2': 8, // 'iPhone 8 Plus',
  'iPhone10,3': 10, // 'iPhone X Global',
  'iPhone10,4': 8, // 'iPhone 8',
  'iPhone10,5': 8, // 'iPhone 8 Plus',
  'iPhone10,6': 10, // 'iPhone X GSM',
  'iPhone11,2': 10, // 'iPhone XS',
  'iPhone11,4': 10, // 'iPhone XS Max',
  'iPhone11,6': 10, // 'iPhone XS Max Global',
  'iPhone11,8': 10, // 'iPhone XR',
  'iPhone12,1': 11, // 'iPhone 11',
  'iPhone12,3': 11, // 'iPhone 11 Pro',
  'iPhone12,5': 11, // 'iPhone 11 Pro Max',
  'iPhone12,8': 8, // 'iPhone SE 2nd Gen',
  'iPhone13,1': 12, // 'iPhone 12 Mini',
  'iPhone13,2': 12, // 'iPhone 12',
  'iPhone13,3': 12, // 'iPhone 12 Pro',
  'iPhone13,4': 12, // 'iPhone 12 Pro Max',
  'iPhone14,2': 13, // 'iPhone 13 Pro',
  'iPhone14,3': 13, // 'iPhone 13 Pro Max',
  'iPhone14,4': 13, // 'iPhone 13 Mini',
  'iPhone14,5': 13, // 'iPhone 13',
  'iPhone14,6': 8, // 'iPhone SE 3rd Gen',
  'iPhone14,7': 14, // 'iPhone 14',
  'iPhone14,8': 14, // 'iPhone 14 Plus',
  'iPhone15,2': 14, // 'iPhone 14 Pro',
  'iPhone15,3': 14, // 'iPhone 14 Pro Max'
};

export const getDeviceClassName = (): string | undefined => {
  if (!window.deviceModel) {
    return undefined;
  }
  const iphoneModelIndex = IPHONE_MODEL_INDEX_BY_MACHINE_NAME[window.deviceModel];

  return iphoneModelIndex <= 8 ? 'device-iphone-lte-8' : 'device-iphone-gte-10';
};

export const sendMessageToMobileApp = (event: MobileAppEventType, payload?: any) => {
  if (window.AppChannel !== undefined) {
    window.AppChannel.postMessage(JSON.stringify({ event, payload }));
  }
};
