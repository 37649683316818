import classNames from 'classnames';
import React, { FC } from 'react';

import './Chip.less';

const Chip: FC<{
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isActive?: boolean
  className?: string
  number?: number
  children: React.ReactNode
}> = ({
  onClick,
  children,
  className,
  isActive,
  number
}) => {
  return (
    <div onClick={onClick} className={classNames('chip', className, { 'active': isActive })}>
      {children}{number !== undefined && <div className="chip__number">{number}</div>}
    </div>
  );
};

export default Chip;
