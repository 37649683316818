import React, { FC } from 'react';

import Button from 'components/Button';
import { IconArrow } from 'components/svg';

import { COLOR_PRIMARY } from 'const';

import './BackButton.less';

const BackButton: FC<{
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}> = ({
  onClick
}) => {
  return (
    <Button className="back-button" color="default" onClick={onClick}>
      <IconArrow className="back-button__icon" color={COLOR_PRIMARY} />
    </Button>
  );
};

export default BackButton;
