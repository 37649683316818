import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
  useDispatch as _useDispatch,
  useSelector as _useSelector,
  TypedUseSelectorHook,
} from 'react-redux';

import type { RootState, AppDispatch } from 'store';

import { getCommonAudio } from 'store/selectors';
import {
  initAudio,
  // clearAudio,
  playAudio,
  stopAudio
} from 'store/common/actions';
import { shallowEqual } from 'utils';

type UseAudioReturnValue = [
  () => void,
  {
    stop: () => void
    isPlaying: boolean
    progress: number
    duration: number
  }
]

export const useDispatch: () => AppDispatch = _useDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;

export const useAudio = (
  src: string,
  options: {
    onStop?: () => void
  } = {}
): UseAudioReturnValue => {
  const { onStop } = options;

  const dispatch = useDispatch();
  const audioState = useSelector(getCommonAudio, shallowEqual);

  // const [isPlaying, setIsPlaying] = useState(audioState.isPlaying);
  const isPlaying = audioState.isPlaying;
  const prevIsPlaying = usePreviousValue(isPlaying);
  const prevSrc = usePreviousValue(audioState.src);

  // useEffect(() => {
  //   if (!audioState.isPlaying || src != audioState.src) {
  //     setIsPlaying(false);
  //   }
  // }, [audioState.isPlaying, audioState.src]);
  useEffect(() => {
    if (prevSrc !== src) {
      dispatch(initAudio(src));
    }
  }, [src]);

  useEffect(() => {
    if (onStop && !isPlaying && prevIsPlaying && prevSrc === src) {
      onStop();
    }
  }, [isPlaying]);

  const play = () => {
    // setIsPlaying(true);
    // dispatch(clearAudio());
    if (src) {
      dispatch(playAudio(src));
    }
  };

  const stop = () => dispatch(stopAudio());

  return [play, {
    isPlaying,
    progress: audioState.progress,
    duration: audioState.duration,
    stop: stop
  }];
};

export const usePreviousValue = <T>(value: T): T => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useTranslation = () => {
  return {
    t: (value: string) => value
  };
};

export const useLocalStorage = <T>(key: string, initialValue: T | (() => T)): [T, (v: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value: T): void => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
};

export const useIsWebView = () => {
  const [isWebView, setIsWebView] = useState(false);

  useLayoutEffect(() => {
    if (window.isWebView) {
      setIsWebView(true);
    }
  }, [window.isWebView]);

  return isWebView;
};

// import ModalQuiz, { QuizOption } from 'components/ModalQuiz';

// type UseQuizModalOptions = {
//   onCorrectOptionClick: (option: QuizOption) => void
// }

// export const useQuizModal = (options: UseQuizModalOptions) => {
//   const initialQuizModalState: {
//     isOpen: boolean
//     options: QuizOption[]
//     correctOptionsIds: string[]
//   } = {
//     isOpen: false,
//     options: [],
//     correctOptionsIds: []
//   };
//   const [quizModalState, setQuizModalState] = useState<typeof initialQuizModalState>(initialQuizModalState);
//   const { onCorrectOptionClick } = options;

//   const handleQuizModalCloseClick = () => {
//     setQuizModalState(() => initialQuizModalState);
//   };

//   const handleQuizModalCorrectOptionClick = (option: QuizOption) => {
//     onCorrectOptionClick(option);
//     setQuizModalState(() => initialQuizModalState);
//   };

//   return React.createElement(ModalQuiz, {
//     ...quizModalState,
//     onCloseClick: handleQuizModalCloseClick,
//     onCorrectOptionClick: handleQuizModalCorrectOptionClick
//   });
// };

const _AUDIO_OBJECTS: { [key: string]: HTMLAudioElement } = {};

export const useLocalAudio = (
  src: string,
  // NOTE: On iOS devices, the audio level is always under the user’s physical control.
  // The volume property is not settable in JavaScript. Reading the volume property always returns 1.
  options?: { volume?: number, withProgress?: boolean }
): UseAudioReturnValue => {
  // https://stackoverflow.com/questions/42695145/how-to-handle-audio-playing-in-react-redux
  // mp3 is the best format for web
  // const audio = new Audio(textAudio);
  // audio.play();
  // const audioRef = useRef<HTMLAudioElement>();
  // const [audio, setAudio] = useState<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  const play = () => {
    audioElem.currentTime = 0;
    audioElem.play().catch((error) => {
      console.error(error);
    });
  };

  const stop = () => {
    audioElem.pause();
    audioElem.currentTime = 0;
  };

  if (!_AUDIO_OBJECTS[src]) {
    _AUDIO_OBJECTS[src] = new Audio(src);
  }

  const audioElem = _AUDIO_OBJECTS[src];

  // if (!audioRef.current) {
  //   audioRef.current = new Audio(src);
  // }
  // console.log(audioRef.current);

  useEffect(() => {
    const onEnded = () => {
      setIsPlaying(false);
      setProgress(100);
    };
    const onPause = () => setIsPlaying(false);
    const onPlay = () => setIsPlaying(true);
    const onPlaying = () => {
      if (options?.withProgress) {
        setProgress(Math.ceil(audioElem.currentTime / audioElem.duration * 100));
      }
    };
    const onCanPlay = () => {
      if (options?.volume) {
        audioElem.volume = options.volume;
      }
    };

    audioElem.addEventListener('ended', onEnded);
    audioElem.addEventListener('timeupdate', onPlaying);
    audioElem.addEventListener('pause', onPause);
    audioElem.addEventListener('play', onPlay);
    audioElem.addEventListener('loadeddata', onCanPlay);

    return () => {
      audioElem.removeEventListener('ended', onEnded);
      audioElem.removeEventListener('timeupdate', onPlaying);
      audioElem.removeEventListener('pause', onPause);
      audioElem.removeEventListener('play', onPlay);
      audioElem.removeEventListener('loadeddata', onCanPlay);
    };
  }, [src]);

  return [play, {
    isPlaying,
    progress: progress,
    stop: stop,
    duration: 0
  }];
};

type VideoEventListenerMap = {
  [EventName in keyof HTMLMediaElementEventMap]?: EventListener;
};

export const useVideoFrames = (
  frameCallback: (videoTime: number) => void
): [HTMLVideoElement | null, React.RefCallback<HTMLVideoElement>] => {
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);

  const callbackRef = useRef(frameCallback);
  callbackRef.current = frameCallback;

  useEffect(() => {
    if (!video) return;

    let frameId: number | null;
    let requestFrame = requestAnimationFrame;
    let cancelFrame = cancelAnimationFrame;

    if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
      // https://web.dev/requestvideoframecallback-rvfc/
      const vid = video as HTMLVideoElement & {
        requestVideoFrameCallback: typeof requestAnimationFrame;
        cancelVideoFrameCallback: typeof cancelAnimationFrame;
      };
      requestFrame = vid.requestVideoFrameCallback.bind(vid);
      cancelFrame = vid.cancelVideoFrameCallback.bind(vid);
    }

    const callbackFrame = (now: number, metadata?: any) => {
      const videoTime = metadata?.mediaTime ?? video.currentTime;
      callbackRef.current(videoTime);
      frameId = requestFrame(callbackFrame);
    };

    const eventListeners: VideoEventListenerMap = {
      loadeddata() {
        requestFrame(() => callbackRef.current(video.currentTime));
      },
      play() {
        frameId = requestFrame(callbackFrame);
      },
      pause() {
        cancelFrame(frameId ?? 0);
        frameId = null;
      },
      timeupdate() {
        if (!frameId) {
          requestFrame(() => callbackRef.current(video.currentTime));
        }
      },
    };

    Object.keys(eventListeners).forEach((eventName) => {
      const eventListener = eventListeners[eventName as keyof HTMLMediaElementEventMap];
      if (eventListener != null) {
        video.addEventListener(eventName, eventListener);
      }
    });

    return () => {
      cancelFrame(frameId ?? 0);

      Object.keys(eventListeners).forEach((eventName) => {
        const eventListener =
          eventListeners[eventName as keyof HTMLMediaElementEventMap];
        if (eventListener != null) {
          video.removeEventListener(eventName, eventListener);
        }
      });
    };
  }, [video]);

  return [video, setVideo];
};
