import { useEffect, useMemo, useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

// import { useLocalAudio, useSelector } from 'hooks';
import { useAudio } from 'v2/contexts/AudioContext';
// import { getSituationById, vocabulary } from 'store/selectors';

import { Phrase, Word } from 'client/types';
// import urls, { reverse } from 'urls';

import { TASK_TYPES, TASK_TYPE_EXAMPLES, TASK_TYPE_FOREIGN_TO_NATIVE, TASK_TYPE_LISTENING, TASK_TYPE_NATIVE_TO_FOREIGN } from './const';
// export const useSituationFromUrlParams = () => {
//   const { situationId } = useParams();
//   return useSelector((state) => getSituationById(state, Number(situationId)));
// };

// export const useVocabularyWordsExercise = () => {
//   const { words } = useSelector(vocabulary.getWordsExercise);
//   return usePhrasesExercise(words);
// };

// export const useSituationPhrasesExercise = (situationId: number) => {
//   const situation = useSelector((state) => getSituationById(state, situationId));
//   const navigate = useNavigate();

//   const handleExitConfirmClick = () => {
//     navigate(reverse(urls.subtopic, { subtopicId: situation.subtopicId }));
//   };

//   const handleNextExerciseClick = () => {
//     navigate(`${reverse(urls.situation, { situationId })}exercise-dialogue/`);
//   };

//   return {
//     situation,
//     handleExitConfirmClick,
//     handleNextExerciseClick
//   };
// };

export const usePhrasesExercise = (
  phrases: Phrase[],
  options?: {
    onStart?: () => void
    onFinish?: () => void
  }
) => {
  const hasExamples = phrases.filter(p => Boolean(((p as Word).examples || []).length)).length > 1;
  const taskTypes = TASK_TYPES.filter(t => t != TASK_TYPE_EXAMPLES || hasExamples);

  const [phraseIndex, setPhraseIndex] = useState(0);
  const [taskIndex, setTaskIndex] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isTaskFinished, setIsTaskFinished] = useState(false);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  // Чтобы каждый раз, когда нажимаешь "повторить задание", порядок фраз был другим.
  const [_phrases, setPhrases] = useState(phrases);

  const taskType = taskTypes[taskIndex];
  const phrase = _phrases[phraseIndex];  // phrases[phraseIndex]
  const phrasesCount = _phrases.length;
  const progress = Math.round(((phraseIndex + 1) / phrasesCount) * 100);
  const hasPrev = phraseIndex > 0;
  const hasNext = phraseIndex < phrasesCount - 1;
  const isLastTask = taskIndex >= taskTypes.length - 1;
  const isFinished = isTaskFinished && isLastTask;
  const taskNumber = taskIndex + 1;

  useEffect(() => {
    reset();
    setPhrases(getShuffledPhrases());
  }, [taskIndex]);

  const handleStartClick = () => {
    // reset();
    setIsStarted(true);
    if (options?.onStart) {
      options.onStart();
    }
  };

  const handleExitClick = () => {
    setIsExitModalOpen(true);
  };

  // const handleExitConfirmClick = () => {
  //   navigate(reverse(urls.subtopic, { subtopicId: situation.subtopicId }));
  // };

  const handleExitCancelClick = () => {
    setIsExitModalOpen(false);
  };

  const handleResetExerciseClick = () => {
    reset();
    setPhrases(_.shuffle(phrases));
    setTaskIndex(0);
  };

  // const handleNextExerciseClick = () => {
  //   navigate(`${reverse(urls.situation, { situationId })}exercise-dialogue/`);
  // };

  const reset = () => {
    setPhraseIndex(0);
    // setIsStarted(false);
    // setIsFinished(false);
    setIsTaskFinished(false);
  };

  const getShuffledPhrases = () => {
    if ([TASK_TYPE_FOREIGN_TO_NATIVE, TASK_TYPE_NATIVE_TO_FOREIGN, TASK_TYPE_LISTENING, TASK_TYPE_EXAMPLES].includes(taskType)) {
      // NOTE: Для раундов с переводом добавляют несколько проходов по фразам, чтобы они лучше
      // запоминались.
      return shuffleWithoutRepeats([...phrases, ...phrases, ...phrases]);
    }
    return _.shuffle(phrases);
  };

  const gotoNextTask = () => {
    if (!isLastTask) {
      setTaskIndex(index => index + 1);
      // setPhrases(getShuffledPhrases());
    }
  };

  const gotoNextPhrase = () => {
    if (hasNext) {
      setPhraseIndex(index => index + 1);
    } else {
      setIsTaskFinished(true);
      if (isLastTask && options?.onFinish) {
        options.onFinish();
      }
    }
  };

  const gotoPrevPhrase = () => {
    if (hasPrev) {
      setPhraseIndex(index => index - 1);
    }
  };

  return {
    isStarted,
    isFinished,
    isTaskFinished,
    isLastTask,
    isExitModalOpen,
    isFirstPhrase: phraseIndex === 0,
    taskNumber,
    // situation,
    tasksCount: taskTypes.length,
    taskType,
    phrase,
    progress,
    hasPrev,
    hasNext,
    handleStartClick,
    handleExitClick,
    // handleExitConfirmClick,
    handleExitCancelClick,
    handleResetExerciseClick,
    // handleNextExerciseClick,
    gotoNextPhrase,
    gotoPrevPhrase,
    gotoNextTask
  };
};

export const usePhrasesExerciseTaskListening = (
  phrase: Phrase,
  onSuccess: () => void,
  options?: { isWord?: boolean }
) => {
  const [playAudio, { isPlaying: isAudioPlaying, stop: stopAudio }] = useAudio(phrase.textAudio);
  // const [playSuccess] = useLocalAudio(require('audio/success.mp3').default);
  const [gapsIndex, setGapsIndex] = useState(0);

  const textDelimiter = options?.isWord ? '' : ' ';
  const textParts = phrase.text.split(textDelimiter).map((p, i) => ({ value: p, index: i }));
  const shuffledTextParts = useMemo(() => _.shuffle(textParts), [phrase.text]);
  const isLastGap = gapsIndex + 1 === textParts.length;
  const isFinished = gapsIndex === textParts.length;

  useEffect(() => {
    setGapsIndex(0);
    playAudio();
  }, [phrase.text]);

  const handlePlayClick = () => {
    if (!phrase.textAudio) {
      return;
    }
    if (isAudioPlaying) {
      stopAudio();
    } else {
      playAudio();
    }
  };

  const handleGapOptionUse = () => {
    setGapsIndex(index => index + 1);
    if (isLastGap) {
      // playSuccess();
      setTimeout(() => {
        onSuccess();
      }, 400);
    } else if (textParts[gapsIndex + 1].value == ' ') {
      setGapsIndex(index => index + 1);
    }
  };

  return {
    isAudioPlaying,
    gapsIndex,
    textParts,
    shuffledTextParts,
    isFinished,
    handlePlayClick,
    handleGapOptionUse
  };
};

// https://blog.logrocket.com/how-to-create-video-audio-recorder-react/
const useVoiceEcho = (options: {
  duration: number
  onAudioEnded?: () => void
}) => {
  const [isPermitted, setIsPermitted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  // what is supported by the browser?
  const mimeType = 'audio/webm';

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream_) => {
        setIsPermitted(true);
        stream_.getTracks().forEach((track) => {
          track.stop();
        });
      })
      .catch(err => console.log(err));
  }, []);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const media = new MediaRecorder(stream, { mimeType });

    let audioChunks: Blob[];

    media.onstart = () => {
      setIsRecording(true);
    };

    media.ondataavailable = (event) => {
      if (typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      audioChunks = [event.data];
    };

    media.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const audioSrc = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioSrc);

      setIsRecording(false);

      stream.getTracks().forEach((track) => {
        track.stop();
      });

      audio.currentTime = 0;
      audio.play().catch((error) => {
        console.error(error);
        if (options.onAudioEnded) {
          options.onAudioEnded();
        }
      });

      audio.onplay = () => {
        setIsPlaying(true);
      };

      audio.onended = () => {
        setIsPlaying(false);
        if (options.onAudioEnded) {
          options.onAudioEnded();
        }
      };

    };

    setTimeout(() => {
      media.stop();
    }, Math.round(options.duration * 1000));

    media.start();
  };

  return { startRecording, isRecording, isPlaying, isPermitted };
};

export const usePhrasesExerciseTaskRecording = (
  phrase: Phrase,
  onSuccess: () => void
) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [playAudio, { duration, isPlaying }] = useAudio(phrase.textAudio);

  const recordDuration = duration * 1.4;

  // const [playSuccess] = useLocalAudio(require('audio/success.mp3').default);

  // NOTE: to turn off echo feature uncomment code below
  const [isRecording, setIsRecording] = useState(false);
  const isRecordPlaying = isSuccess;

  // NOTE: to turn off echo feature comment code below
  // NOTE: сейчас запись в webview работает крайне дерьмово, так что отключаю пока.
  // const { startRecording, isRecording, isPlaying: isRecordPlaying } = useVoiceEcho({
  //   duration: recordDuration,
  //   onAudioEnded() {
  //     setIsSuccess(true);
  //   },
  // });

  useEffect(() => {
    if (isSuccess) {
      // playSuccess();
      const timerId = setTimeout(() => {
        onSuccess();
      }, 600);
      return () => clearTimeout(timerId);
    }
  }, [isSuccess]);

  useEffect(() => {
    // setIsRecording(false);
    setIsSuccess(false);
    playAudio();
  }, [phrase]);

  const handleRecordButtonClick = () => {
    if (isPlaying || isRecordPlaying || isSuccess) {
      return;
    }

    // NOTE: to turn off echo feature comment line below
    // startRecording();

    // NOTE: to turn off echo feature uncomment code below
    setIsRecording(true);
    setTimeout(() => {
      setIsRecording(false);
      setIsSuccess(true);
    }, Math.round(recordDuration * 1000));
  };

  return {
    isSuccess,
    isRecording,
    isRecordPlaying,
    isPlaying,
    handleRecordButtonClick
  };
};

export const useOptionsFromPhrases = (phrase: Phrase, allPhrases: Phrase[]): Phrase[] => {
  return useMemo(() => {
    const wrongAnswer = _.sample(allPhrases.filter(ph => ph.id !== phrase.id));
    return _.shuffle([wrongAnswer, phrase]);
  }, [phrase]);
};

const shuffleWithoutRepeats = <T>(array: T[]): T[] => {
  let result: T[];
  do {
    result = _.shuffle(array);
  } while (hasConsecutiveDuplicates(result));
  return result;
};

const hasConsecutiveDuplicates = <T>(array: T[]): boolean => {
  for (let i = 1; i < array.length; i++) {
    if (array[i] === array[i - 1]) {
      return true;
    }
  }
  return false;
};
