import React, { FC, SVGProps } from 'react';
import { COLOR_PRIMARY } from 'const';

const IconProfile: FC<SVGProps<SVGSVGElement> & {
  isFilled?: boolean
}> = ({
  isFilled,
  color = COLOR_PRIMARY
}) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.125 21V19C20.125 17.9391 19.7036 16.9217 18.9534 16.1716C18.2033 15.4214 17.1859 15 16.125 15H8.125C7.06413 15 6.04672 15.4214 5.29657 16.1716C4.54643 16.9217 4.125 17.9391 4.125 19V21" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.125 11C14.3341 11 16.125 9.20914 16.125 7C16.125 4.79086 14.3341 3 12.125 3C9.91586 3 8.125 4.79086 8.125 7C8.125 9.20914 9.91586 11 12.125 11Z" fill={isFilled ? color : undefined} stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default IconProfile;
