import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import store from 'store';
import Application from 'components/Application';
import App from 'v2/App';
import { AppProvider } from 'v2/contexts/AppContext';

import '@csstools/normalize.css';
import './styles/common.less';
import { AudioPorvider } from 'v2/contexts/AudioContext';

const root = createRoot(document.getElementById('app'));
root.render(
  <AppProvider>
    <AudioPorvider>
      <App />
    </AudioPorvider>
  </AppProvider>
);
// root.render(
//   <Provider store={store}>
//     <AudioPorvider>
//       <Application />
//     </AudioPorvider>
//   </Provider>
// );
