export default {
  // login: '/login/',
  socialAuth: '/social-auth/:backend/',
  vocabulary: {
    index: '/vocabulary/',
    subtopic: '/vocabulary/:subtopicId/',
    wordsExercise: '/vocabulary/words/exercise/',
    wordsFilter: '/vocabulary/words/filter/'
  },
  courseSelection: '/course-selection/',
  profile: '/profile/',
  topics: '/',
  subtopic: '/subtopics/:subtopicId/',
  subtopicVocabulary: '/subtopics/:subtopicId/vocabulary/',
  subtopicVocabularyExercise: '/subtopics/:subtopicId/vocabulary/exercise/',
  situation: '/situations/:situationId/',
  exercises: {
    dialogueIntro: '/situations/:situationId/exercise-dialogue-intro/',
    dialogue: '/situations/:situationId/exercise-dialogue/',
    phrases: '/situations/:situationId/exercise-phrases/',
    grammar: '/situations/:situationId/exercise-grammar/',
    eestiGrammar: {
      olema: '/subtopics/:subtopicId/exercise-grammar/eesti/olema/',
      kuhu: '/subtopics/:subtopicId/exercise-grammar/eesti/kuhu/',
      verbsConjugation: '/subtopics/:subtopicId/exercise-grammar/eesti/verbs-conjugation/',
      pluralForms: '/subtopics/:subtopicId/exercise-grammar/eesti/plural-forms/',
      pluralFormsWithNumber: '/subtopics/:subtopicId/exercise-grammar/eesti/plural-forms-with-numbers/'
    }
  }
};

export const reverse = (url: string, params?: { [Key: string]: string | number }): string => {
  if (!params) {
    return url;
  }
  for (const key in params) {
    url = url.replace(`:${key}`, String(params[key]));
  }
  return url;
};
