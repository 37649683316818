export const SOCIAL_AUTH_BACKEND_GOOGLE = 'google-oauth2';
export const SOCIAL_AUTH_BACKEND_INSTAGRAM = 'instagram';

export const EXERCISE_TYPE_DIALOGUE_INTRO = 'DIALOGUE_INTRO';
export const EXERCISE_TYPE_DIALOGUE = 'DIALOGUE';
export const EXERCISE_TYPE_PHRASES = 'PHRASES';
export const EXERCISE_TYPE_GRAMMAR = 'GRAMMAR';
export const EXERCISE_TYPE_WORDS = 'WORDS';

export type ExerciseType =
  typeof EXERCISE_TYPE_DIALOGUE_INTRO |
  typeof EXERCISE_TYPE_DIALOGUE |
  typeof EXERCISE_TYPE_PHRASES |
  typeof EXERCISE_TYPE_GRAMMAR

export const FILTER_LEARNED_LEVEL_ALL = 'ALL';
export const FILTER_LEARNED_LEVEL_LEARNED = 'LEARNED';
export const FILTER_LEARNED_LEVEL_NOT_LEARNED = 'NOT_LEARNED';
export const FILTER_LEARNED_LEVEL_NEW = 'NEW';

export type FilterLearnedLevelType =
  typeof FILTER_LEARNED_LEVEL_ALL |
  typeof FILTER_LEARNED_LEVEL_NEW |
  typeof FILTER_LEARNED_LEVEL_LEARNED |
  typeof FILTER_LEARNED_LEVEL_NOT_LEARNED;

export const WORD_TYPE_VERB = 'verb';
export const WORD_TYPE_NOUN = 'noun';
export const WORD_TYPE_ADJECTIVE = 'adjective';
export const WORD_TYPE_NUMERAL = 'numeral';
export const WORD_TYPE_OTHER = 'other';

export type WordType =
  typeof WORD_TYPE_VERB |
  typeof WORD_TYPE_NOUN |
  typeof WORD_TYPE_ADJECTIVE |
  typeof WORD_TYPE_NUMERAL |
  typeof WORD_TYPE_OTHER;

export const PROGRESS_LEVEL_ZERO = 'PROGRESS_LEVEL_ZERO';
export const PROGRESS_LEVEL_LOW = 'PROGRESS_LEVEL_LOW';
export const PROGRESS_LEVEL_MEDIUM = 'PROGRESS_LEVEL_MEDIUM';
export const PROGRESS_LEVEL_HIGH = 'PROGRESS_LEVEL_HIGH';

export const COLOR_NEUTRAL_0 = '#fff';
export const COLOR_NEUTRAL_200 = '#EDF1F4';
export const COLOR_NEUTRAL_300 = '#DDE6EB';
export const COLOR_NEUTRAL_500 = '#CAD4DB';

export const COLOR_PRIMARY = '#667F8E';
export const COLOR_SECONDARY = '#613EE8';
export const COLOR_SECONDARY_LINEAR = '#A73EE8';

export const EXERCISE_REPEATED_COUNT_COMPLETED = 4;
export const WORDS_EXERCISE_REPEATED_COUNT_COMPLETED = 6;
export const TOPICS_IN_PROGRESS_MAX_COUNT = 4;

export const MOBILE_APP_EVENT_USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const MOBILE_APP_EVENT_APP_IS_READY = 'APP_IS_READY';

export type MobileAppEventType =
  typeof MOBILE_APP_EVENT_USER_LOGGED_OUT |
  typeof MOBILE_APP_EVENT_APP_IS_READY;

export const GRAMMAR_QUIZ_GAP_TYPE_ALL_HIDDEN = 'ALL_HIDDEN';
export const GRAMMAR_QUIZ_GAP_TYPE_FIRST_LETTER = 'FIRST_LETTER';
export const GRAMMAR_QUIZ_GAP_TYPE_ENDING = 'ENDING';

export type GrammarQuizGapType =
  typeof GRAMMAR_QUIZ_GAP_TYPE_ALL_HIDDEN |
  typeof GRAMMAR_QUIZ_GAP_TYPE_FIRST_LETTER |
  typeof GRAMMAR_QUIZ_GAP_TYPE_ENDING;

export const STORAGE_WORDS_REPEATED_COUNT_BY_ID = 'WORDS_REPEATED_COUNT_BY_ID';
export const STORAGE_WORDS_EXCLUDED_COUNT_BY_ID = 'WORDS_EXCLUDED_COUNT_BY_ID';
export const STORAGE_CONFIG = 'CONFIG';

export const GRAMMAR_QUIZ_TYPE_OLEMA_STATEMENT = 'OLEMA_STATEMENT';
export const GRAMMAR_QUIZ_TYPE_ABOUT_HOME = 'ABOUT_HOME';
export const GRAMMAR_QUIZ_TYPE_SIMPLE_NUMBERS = 'SIMPLE_NUMBERS';

export type GrammarQuizType =
  typeof GRAMMAR_QUIZ_TYPE_OLEMA_STATEMENT |
  typeof GRAMMAR_QUIZ_TYPE_ABOUT_HOME |
  typeof GRAMMAR_QUIZ_TYPE_SIMPLE_NUMBERS;
