import type { ExerciseType } from 'const';

export default {
  initData: (): string => '/api/init/',
  user: (): string => '/api/users/authenticated/',
  topics: (): string => '/api/exercises/topics/',
  courseDetail: (courseId: number): string => `/api/topics/courses/${courseId}/`,
  tokenAuth: (): string => '/api/auth/',
  socialAuthBegin: (backend: string): string => `/api/social-auth/${backend}/begin/`,
  socialAuthComplete: (backend: string, queryString: string): string => {
    queryString = queryString.startsWith('?') ? queryString : `?${queryString}`;
    return `/api/social-auth/${backend}/complete/${queryString}`;
  },
  vocabularyUserWords: (): string => '/api/vocabulary/user-words/',
  vocabulary: {
    userWords: (language?: string) => `/api/vocabulary/user-words${language ? '?language=' + language : ''}`,
    words: (language?: string) => `/api/vocabulary/words${language ? '?language=' + language : ''}`,
    incrementUserWordsRepeatedCount: () => '/api/vocabulary/user-words/increment-repeated-count/'
  },
  situationExerciseSessionStart: (situationId: number, exerciseType: ExerciseType): string => `/api/topics/situations/${situationId}/${exerciseType}/session-start/`,
  situationExerciseSessionFinish: (situationId: number, exerciseType: ExerciseType): string => `/api/topics/situations/${situationId}/${exerciseType}/session-finish/`,
  situationExerciseSessionCancel: (situationId: number, exerciseType: ExerciseType): string => `/api/topics/situations/${situationId}/${exerciseType}/session-cancel/`,
  exerciseDetail: (exerciseId: number): string => `/api/exercises/${exerciseId}/`,
  exerciseSessionStart: (exerciseId: number): string => `/api/exercises/${exerciseId}/session/start/`,
  exerciseSessionFinish: (exerciseId: number): string => `/api/exercises/${exerciseId}/session/finish/`,
};
