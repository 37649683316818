import classNames from 'classnames';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type * as client from 'client';

import ExerciseGapOption from 'components/ExerciseGapOption';
import ExerciseGapPlaceholder from 'components/ExerciseGapPlaceholder';
import ExerciseTopbar from 'components/ExerciseTopbar';
import Layout from 'components/Layout';
import { IconShow } from 'components/svg';

import { COLOR_NEUTRAL_0 } from 'const';
import urls, { reverse } from 'urls';

import {
  DIFFICULTY_LEVEL_EASY,
  DIFFICULTY_LEVEL_HARD,
  DIFFICULTY_LEVEL_MEDIUM,
  DifficultyLevelType,
  useGrammarCard,
  useKuhuCardFactory,
  useOlemaVerbCardFactory,
  usePluralEndingExercise,
  usePluralWithNumberEndingExercise,
  useVerbConjugationCardFactory
} from './hooks';

import './GrammarExercise.less';

type TextPart = {
  text: string
  isGap?: boolean
  isEnding?: boolean
  gap?: {
    visibleText: string
    hiddenText: string
    isCurrent: boolean
    isShown: boolean
  }
}

const VerbConjugationGrammarExercise: FC = () => {
  const navigate = useNavigate();

  const cardFactory = useVerbConjugationCardFactory();
  const {
    textParts,
    textTranslation,
    next,
    options,
    isFinished,
    refresh
  } = useGrammarCard(cardFactory);

  useEffect(() => {
    if (isFinished) {
      const timerId = setTimeout(() => {
        refresh();
      }, 800);
      return () => clearTimeout(timerId);
    }
  }, [isFinished]);

  const handleExit = () => {
    navigate('/');
  };

  return <GrammarExercise
    textParts={textParts}
    translation={textTranslation}
    options={options}
    onCorrectOptionClick={next}
    onExit={handleExit}
  />;
};

const OlemaVerbGrammarExercise: FC = () => {
  const navigate = useNavigate();
  const cardFactory = useOlemaVerbCardFactory();
  const {
    textParts,
    textTranslation,
    next,
    options,
    isFinished,
    refresh
  } = useGrammarCard(cardFactory);

  useEffect(() => {
    if (isFinished) {
      const timerId = setTimeout(() => {
        refresh();
      }, 1200);
      return () => clearTimeout(timerId);
    }
  }, [isFinished]);

  const handleExit = () => {
    navigate('/');
  };

  return <GrammarExercise
    textParts={textParts}
    translation={textTranslation}
    options={options}
    onExit={handleExit}
    onCorrectOptionClick={next}
  />;
};

// useKustGrammarExercise
// useKusGrammarExercise

const KuhuGrammarExercise: FC = () => {
  const navigate = useNavigate();

  const { subtopicId } = useParams<{ subtopicId: string }>();
  const [passedCardsCount, setPassedCardsCount] = useState(0);

  let difficultyLevel: DifficultyLevelType;
  if (passedCardsCount < 4) {
    difficultyLevel = DIFFICULTY_LEVEL_EASY;
  } else if (passedCardsCount < 8) {
    difficultyLevel = DIFFICULTY_LEVEL_MEDIUM;
  } else {
    difficultyLevel = DIFFICULTY_LEVEL_HARD;
  }

  const cardFactory = useKuhuCardFactory(subtopicId, difficultyLevel);
  const {
    question,
    clues,
    textParts,
    textTranslation,
    next,
    options,
    isFinished,
    refresh
  } = useGrammarCard(cardFactory);

  useEffect(() => {
    if (isFinished) {
      setPassedCardsCount(value => value + 1);
      const timerId = setTimeout(() => {
        refresh();
      }, 1200);
      return () => clearTimeout(timerId);
    }
  }, [isFinished]);

  const handleExit = () => {
    navigate(reverse(urls.subtopic, { subtopicId }));
  };

  return <GrammarExercise
    isOptionsOverlayShown={passedCardsCount > 4}
    question={question}
    clues={clues}
    textParts={textParts}
    // rule={card.rule}
    translation={textTranslation}
    options={options}
    onExit={handleExit}
    onCorrectOptionClick={next}
  />;
};

const PluralFormWithNumberGrammarExercise: FC = () => {
  const navigate = useNavigate();

  const { subtopicId } = useParams<{ subtopicId: string }>();
  const { makeCard } = usePluralWithNumberEndingExercise(subtopicId);

  const [card, setCard] = useState(() => makeCard());

  const handleNext = () => {
    setCard(makeCard());
  };

  const handleExit = () => {
    navigate(reverse(urls.subtopic, { subtopicId }));
  };

  return <GrammarExercise
    textParts={card.textParts}
    rule={card.rule}
    translation={card.translation}
    options={card.options}
    onExit={handleExit}
    onNext={handleNext}
  />;
};

const PluralFormGrammarExercise: FC = () => {
  const navigate = useNavigate();

  const { subtopicId } = useParams<{ subtopicId: string }>();
  const { makeCard } = usePluralEndingExercise(subtopicId);

  const [card, setCard] = useState(() => makeCard());

  const handleNext = () => {
    setCard(makeCard());
  };

  const handleExit = () => {
    navigate(reverse(urls.subtopic, { subtopicId }));
  };

  return <GrammarExercise
    textParts={card.textParts}
    rule={card.rule}
    translation={card.translation}
    options={card.options}
    onExit={handleExit}
    onNext={handleNext}
  />;
};

const GrammarExercise: FC<{
  question?: {
    text: string
    textTranslation: string
    textAudio?: string
  }
  progress?: number
  progressLabel?: string
  isOptionsOverlayShown?: boolean
  isOptionsHidden?: boolean
  clues?: client.types.Word[]
  textParts: TextPart[]
  textAudio?: string
  rule?: string
  translation?: string
  options: {
    isCorrect: boolean
    value: string
  }[]
  onCorrectOptionClick?: () => void
  onWrongOptionClick?: () => void
  onExit: () => void
  onNext?: () => void
}> = ({
  isOptionsOverlayShown: defaultIsOptionsOverlayShown,
  question,
  progress = 0,
  progressLabel = '00:00',
  clues,
  textParts,
  rule,
  translation,
  options,
  onExit,
  onNext,
  onCorrectOptionClick,
  onWrongOptionClick
}) => {
  const [isGapShown, setIsGapShown] = useState(false);
  const [isOptionsOverlayShown, setIsOptionsOverlayShown] = useState(defaultIsOptionsOverlayShown);

  const handleCorrectClick = () => {
    onCorrectOptionClick && onCorrectOptionClick();
    setIsGapShown(true);
    if (!onCorrectOptionClick && onNext) {
      setTimeout(() => {
        !onCorrectOptionClick && onNext();
      }, 800);
    }
  };

  const handleOptionsOverlayClick = () => {
    setIsOptionsOverlayShown(false);
  };

  useEffect(() => {
    if (isGapShown) {
      setIsGapShown(false);
    }
  }, [options]);

  useEffect(() => {
    setIsOptionsOverlayShown(defaultIsOptionsOverlayShown);
  }, [options]);

  return (
    <Layout
      noScroll
      isBgWhite
      className="grammar-exercise"
    >
      <Layout.Header>
        <ExerciseTopbar
          progress={progress}
          label={progressLabel}
          onCloseClick={onExit}
        />
      </Layout.Header>
      <Layout.Row className="grammar-exercise__inner">
        {rule && <div className="grammar-exercise__rule">{rule}</div>}
        {question && <div className="grammar-exercise__question">
          <div className="grammar-exercise__question-text">{question.text}</div>
          <div className="grammar-exercise__translation">{question.textTranslation}</div>
        </div>}
        {clues && <div className="grammar-exercise__clues">
          {clues.map(clue => (
            <div className="grammar-exercise__clue" key={clue.id}>
              {clue.textTranslation}
            </div>
          ))}
        </div>}
        <div className="grammar-exercise__content">
          <div className="grammar-exercise__text">
            {textParts.map((part, i) => {
              return <GrammarExerciseTextPart
                textPart={part}
                isFirst={i == 0}
                key={i}
              />;
            })}
          </div>
          {translation && <div className="grammar-exercise__translation">{_.capitalize(translation)}</div>}
        </div>
        <div className="grammar-exercise__options">
          {isOptionsOverlayShown && <GrammarExerciseOptionsOverlay onClick={handleOptionsOverlayClick} />}
          {options.map((opt, index) => (
            <ExerciseGapOption
              key={index}
              // key={opt.value}
              size="large"
              className="grammar-exercise__option"
              value={opt.value ? opt.value : '–'}
              isCorrect={opt.isCorrect}
              isUsed={opt.isCorrect && isGapShown}
              onUse={handleCorrectClick}
              onWrong={onWrongOptionClick}
            />
          ))}
        </div>
      </Layout.Row>
    </Layout>
  );
};

const GrammarExerciseTextPart: FC<{
  textPart: TextPart
  isFirst?: boolean
}> = ({
  textPart,
  isFirst
}) => {
  const { gap } = textPart;

  if (!gap) {
    return <React.Fragment >{isFirst ? _.capitalize(textPart.text) : textPart.text} </React.Fragment>;
  }
  if (gap.visibleText) {
    const visibleText = isFirst ? _.capitalize(gap.visibleText) : gap.visibleText;

    let hiddenText = gap.hiddenText;
    if (visibleText.length == 1) {
      hiddenText = hiddenText.slice(1);
    }
    if (gap.isShown) {
      return <React.Fragment>
        {visibleText}
        <div className="grammar-exercise__ending">{hiddenText} </div>
      </React.Fragment>;
    } else {
      return <React.Fragment>
        {visibleText}
        <div
          className={classNames(
            'grammar-exercise__ending-gap',
            { 'grammar-exercise__ending-gap--current': gap.isCurrent }
          )}
        />
      </React.Fragment>;
    }
  }
  return <ExerciseGapPlaceholder
    isInline
    isActive={gap.isCurrent}
    isValueShown={gap.isShown}
    isSuccess={gap.isShown}
    value={gap.hiddenText}
    className="grammar-exercise__placeholder"
  />;
};

const GrammarExerciseOptionsOverlay: FC<{
  onClick: () => void
}> = ({
  onClick
}) => {
  return (
    <div className="grammar-exercise__options-overlay" onClick={onClick}>
      <div className="grammar-exercise__options-overlay-button">
        <IconShow color={COLOR_NEUTRAL_0} />
      </div>
    </div>
  );
};

export {
  KuhuGrammarExercise, OlemaVerbGrammarExercise,
  PluralFormGrammarExercise,
  PluralFormWithNumberGrammarExercise, VerbConjugationGrammarExercise
};

export default GrammarExercise;

//<Layout.Row className="grammar-exercise__inner">
//  {/* <div className="grammar-exercise__rule">Правило</div> */}
//  <div className="grammar-exercise__content">
//    {/* <div className="grammar-exercise__text">
//      I<ExerciseGapPlaceholder isInline num={1} />a new manager
//      of this fucking<ExerciseGapPlaceholder isInline num={2} />office
//    </div> */}
//    <div className="grammar-exercise__text">
//      Sina<ExerciseGapPlaceholder isInline />ilsu
//    </div>
//    {/* <div className="grammar-exercise__text">Я новый менеджер</div> */}
//    {/* <div className="grammar-exercise__placeholders">
//      <ExerciseGapPlaceholder className="grammar-exercise__placeholder" isActive />
//      <ExerciseGapPlaceholder className="grammar-exercise__placeholder" />
//      <ExerciseGapPlaceholder className="grammar-exercise__placeholder" />
//    </div> */}
//  </div>
//  <div className="grammar-exercise__options" data-number={2}>
//    <ExerciseGapOption className="grammar-exercise__option" value="oled" onUse={() => null} />
//    <ExerciseGapOption className="grammar-exercise__option" value="on" onUse={() => null} />
//    {/* <ExerciseGapOption className="grammar-exercise__option" value="a new manager" onUse={() => null} /> */}
//  </div>
//</Layout.Row>
