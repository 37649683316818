import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { IconArrow } from 'components/svg';

import './Topbar.less';

const Topbar: FC<{
  title?: string
  children?: ReactNode,
  className?: string,
  backLink?: string,
  onCloseClick?: (event: React.MouseEvent<HTMLElement>) => void,
  onBackClick?: (event: React.MouseEvent<HTMLElement>) => void
}> = ({
  title,
  children,
  className,
  backLink,
  onBackClick,
  onCloseClick
}) => {
  const navigate = useNavigate();

  const handleBackClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onBackClick) {
      onBackClick(event);
    } else if (backLink) {
      navigate(backLink);
    }
  };

  const hasLeftButton = onBackClick || backLink;
  const hasRightButton = Boolean(onCloseClick);

  return (
    <div className={classNames(
      'topbar',
      className,
      {
        'topbar--has-left-button': hasLeftButton,
        'topbar--has-right-button': hasRightButton,
        'topbar--has-title': Boolean(title)
      }
    )}>
      {(onBackClick || backLink) && <div
        className="topbar__left-button-wrapper"
        onClick={handleBackClick}
      >
        <IconArrow className="topbar__back-button" />
      </div>}
      {title && !children && <div className="topbar__title">{title}</div>}
      {children}
      {onCloseClick && <div
        className="topbar__right-button-wrapper"
        onClick={onCloseClick}
      >
        <div className="topbar__close-button" />
      </div>}
    </div>
  );
};

export default Topbar;
