import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

import Layout from 'components/Layout';
import ExerciseTopbar from 'components/ExerciseTopbar';
import Button from 'components/Button';
import BackButton from 'components/BackButton';
import RecordButton from 'components/RecordButton';
import ButtonGroup from 'components/ButtonGroup';
import ExerciseGapOption from 'components/ExerciseGapOption';
import ExerciseGapPlaceholder from 'components/ExerciseGapPlaceholder';
import ExerciseInfo from 'components/ExerciseInfo';
import ExerciseExitModal from 'components/ExerciseExitModal';
import { IconShow, IconSpeaker } from 'components/svg';

import { capitalize } from 'utils';
import { getSituationById, getSubtopicById, vocabulary } from 'store/selectors';
import { useDispatch, useLocalAudio, useSelector } from 'hooks';
import { useAudio } from 'v2/contexts/AudioContext';
import urls, { reverse } from 'urls';

import type { Phrase, Word, WordExample } from 'client/types';

import {
  usePhrasesExerciseTaskListening,
  usePhrasesExercise,
  usePhrasesExerciseTaskRecording,
  useOptionsFromPhrases,
  // useSituationPhrasesExercise,
  // useSituationFromUrlParams
} from './hooks';
import {
  EXAMPLES_GAP_ALL,
  EXAMPLES_GAP_ENDING,
  EXAMPLES_READING,
  ExamplesTaskSubtype,
  TASK_TYPE_EXAMPLES,
  TASK_TYPE_FOREIGN_TO_NATIVE,
  TASK_TYPE_LISTENING,
  TASK_TYPE_NATIVE_TO_FOREIGN,
  TASK_TYPE_READING,
  TASK_TYPE_RECORDING
} from './const';

import { finishExercise, startExercise } from 'store/courses/actions';
import * as vocabularyActions from 'store/vocabulary/actions';
import { COLOR_NEUTRAL_0, EXERCISE_TYPE_PHRASES } from 'const';

import './PhrasesExercise.less';
import ExerciseEnd from 'components/ExerciseEnd';
import { getNumberCaptionRU } from 'utils';

const EXERCISE_TASKS = [
  { text: 'Прочитайте и постарайтесь запомнить' },
  { text: 'Выберете правильный перевод' },
  { text: 'Выберете правильный перевод' },
  // TODO: переделать механику
  { text: 'Прослушайте и составьте фразу' },
  { text: 'Повторите фразу' }
];

// TODO: возможно выделить в отдельный компонент VocabularyWordsExercise
export const VocabularyPhrasesExercise: FC = () => {
  const { words } = useSelector(vocabulary.getWordsExercise);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!words.length) {
      navigate(urls.vocabulary.index);
    }
  }, [words]);

  const handleExitConfirmClick = () => {
    navigate(urls.vocabulary.index);
  };

  const handleFinish = () => {
    dispatch(vocabularyActions.finishWordsExericse(words));
  };

  const handleStart = () => {
    dispatch(vocabularyActions.startWordsExericse(words));
  };

  return <PhrasesExercise
    backLink={urls.vocabulary.index}
    exerciseName="Слова"
    title="📚 Мой словарь"
    isWords
    phrases={words}
    onExitConfirmClick={handleExitConfirmClick}
    onStart={handleStart}
    onFinish={handleFinish}
  />;
};

export const SubtopicVocabularyPhrasesExercise: FC = () => {
  const { subtopicId } = useParams();

  const subtopic = useSelector(state => getSubtopicById(state, Number(subtopicId)));
  const { words } = useSelector(vocabulary.getWordsExercise);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backUrl = reverse(urls.subtopicVocabulary, { subtopicId });

  useEffect(() => {
    if (!words.length) {
      navigate(backUrl);
    }
  }, [words]);

  const handleExitConfirmClick = () => {
    navigate(backUrl);
  };

  const handleFinish = () => {
    dispatch(vocabularyActions.finishWordsExericse(words));
  };

  const handleStart = () => {
    dispatch(vocabularyActions.startWordsExericse(words));
  };

  return <PhrasesExercise
    backLink={backUrl}
    exerciseName="Слова"
    title={subtopic.title}
    isWords
    phrases={words}
    onExitConfirmClick={handleExitConfirmClick}
    onStart={handleStart}
    onFinish={handleFinish}
  />;
};

export const SituationPhrasesExercise: FC = () => {
  const { situationId } = useParams();
  const situation = useSelector((state) => getSituationById(state, Number(situationId)));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleExitConfirmClick = () => {
    navigate(reverse(urls.subtopic, { subtopicId: situation.subtopicId }));
  };

  const handleNextExerciseClick = () => {
    navigate(`${reverse(urls.situation, { situationId: situation.id })}exercise-dialogue/`);
  };

  const handleStart = () => {
    dispatch(startExercise(EXERCISE_TYPE_PHRASES, situation.id));
  };

  const handleFinish = () => {
    dispatch(finishExercise(EXERCISE_TYPE_PHRASES, situation.id));
  };

  return <PhrasesExercise
    backLink={reverse(urls.subtopic, { subtopicId: situation.subtopicId })}
    exerciseName="Фразы"
    title={situation.title}
    phrases={situation.phrases}
    onExitConfirmClick={handleExitConfirmClick}
    onNextExerciseClick={handleNextExerciseClick}
    onStart={handleStart}
    onFinish={handleFinish}
  />;
};

const PhrasesExercise: FC<{
  backLink?: string
  exerciseName: string
  title: string
  isWords?: boolean
  phrases: Phrase[]
  onExitConfirmClick: () => void
  onNextExerciseClick?: () => void
  onStart?: () => void
  onFinish?: () => void
  onBackClick?: () => void
}> = ({
  backLink,
  exerciseName,
  title,
  phrases,
  isWords,
  onExitConfirmClick,
  onNextExerciseClick,
  onStart,
  onFinish,
  onBackClick
}) => {
  // const { situationId } = useParams();
  const {
    isFinished,
    isStarted,
    isTaskFinished,
    isExitModalOpen,
    isFirstPhrase,
    phrase,
    tasksCount,
    taskNumber,
    progress,
    taskType,
    gotoNextPhrase,
    gotoPrevPhrase,
    gotoNextTask,
    handleStartClick,
    handleExitClick,
    handleExitCancelClick,
    handleResetExerciseClick
    // situation,
    // handleExitConfirmClick,
    // handleNextExerciseClick
  } = usePhrasesExercise(phrases, { onStart, onFinish });

  if (!isStarted) {
    return <ExerciseInfo
      exerciseType={{ name: exerciseName, icon: '💬' }}
      backLink={backLink}
      title={title}
      tasks={EXERCISE_TASKS}
      isHeadphonesShown
      isMicrophoneShown
      onStartClick={handleStartClick}
      onBackClick={onBackClick}
    />;
  }

  const tasksCountLeft = tasksCount - taskNumber;

  if (!isFinished && isTaskFinished) {
    return <ExerciseEnd
      title={title}
      emoji="👍"
      text="Задание выполнено!"
      note={`Еще ${tasksCountLeft} ${getNumberCaptionRU(tasksCountLeft, ['задание', 'задания', 'заданий'])}`}
      onNextTaskClick={gotoNextTask}
      onExitConfirmClick={onExitConfirmClick}
    />;
  }

  if (isFinished) {
    return <ExerciseEnd
      title={title}
      emoji="🚀"
      text="Упражнение выполнено!"
      isExerciseFinished
      onRepeatExerciseClick={handleResetExerciseClick}
      onNextExerciseClick={onNextExerciseClick}
      onExitConfirmClick={onExitConfirmClick}
    />;
  }

  return (
    <Layout
      noScroll
      isBgWhite
      className="phrases-exercise"
    >
      <Layout.Header>
        <ExerciseTopbar
          roundNumber={taskNumber}
          roundsCount={tasksCount}
          progress={progress}
          onCloseClick={handleExitClick}
        />
      </Layout.Header>
      {isExitModalOpen && <ExerciseExitModal
        onCancelClick={handleExitCancelClick}
        onConfirmClick={onExitConfirmClick}
      />}
      {taskType == TASK_TYPE_READING && phrase.image && <div className="phrases-exercise__img">
        <div className="phrases-exercise__img-inner">
          <img src={phrase.image} alt={phrase.text} />
        </div>
      </div>}
      {taskType == TASK_TYPE_READING && <PhrasesExerciseTaskReading
        isFirstPhrase={isFirstPhrase}
        phrase={phrase}
        onBackClick={gotoPrevPhrase}
        onNextClick={gotoNextPhrase}
      />}
      {taskType == TASK_TYPE_FOREIGN_TO_NATIVE && <PhrasesExerciseTaskForeignToNative
        isFirstPhrase={isFirstPhrase}
        phrase={phrase}
        allPhrases={phrases}
        onRightAnswer={gotoNextPhrase}
      />}
      {taskType == TASK_TYPE_NATIVE_TO_FOREIGN && <PhrasesExerciseTaskNativeToForeign
        isFirstPhrase={isFirstPhrase}
        phrase={phrase}
        allPhrases={phrases}
        onRightAnswer={gotoNextPhrase}
      />}
      {isWords && taskType == TASK_TYPE_EXAMPLES && <PhrasesExerciseTaskExamples
        word={phrase as Word}
        onNextClick={gotoNextPhrase}
      />}
      {taskType == TASK_TYPE_LISTENING && <PhrasesExerciseTaskListening
        isFirstPhrase={isFirstPhrase}
        isWord={isWords}
        phrase={phrase}
        allPhrases={phrases}
        onSuccess={gotoNextPhrase}
      />}
      {taskType == TASK_TYPE_RECORDING && <PhrasesExerciseTaskRecording
        isFirstPhrase={isFirstPhrase}
        phrase={phrase}
        onSuccess={gotoNextPhrase}
      />}
    </Layout>
  );
};

const PhrasesExerciseTaskReading: FC<{
  isFirstPhrase?: boolean
  phrase: Phrase,
  onBackClick: (event: React.MouseEvent<HTMLElement>) => void,
  onNextClick: (event: React.MouseEvent<HTMLElement>) => void
}> = ({
  isFirstPhrase,
  phrase,
  onBackClick,
  onNextClick
}) => {
  return (
    <>
      <div className="phrases-exercise__content">
        <PhrasesExerciseText value={phrase.text} audio={phrase.textAudio} autoplay />
        <div className="phrases-exercise__translation">{capitalize(phrase.textTranslation)}</div>
        {isFirstPhrase && <PhrasesExerciseTaskDescription text="Прочитайте и постарайтесь запомнить" />}
      </div>
      <ButtonGroup className="phrases-exercise__footer" direction="row">
        <BackButton onClick={onBackClick} />
        <Button color="secondary" isWide onClick={onNextClick}>Далее</Button>
      </ButtonGroup>
    </>
  );
};

const PhrasesExerciseTaskForeignToNative: FC<{
  isFirstPhrase?: boolean
  phrase: Phrase
  allPhrases: Phrase[]
  onRightAnswer: () => void
}> = ({
  isFirstPhrase,
  phrase,
  allPhrases,
  onRightAnswer
}) => {
  const [isOptionsOverlayShown, setIsOptionsOverlayShown] = useState(true);

  useEffect(() => {
    setIsOptionsOverlayShown(true);
  }, [phrase]);

  const options = useMemo(() => {
    const wrongAnswer = _.sample(allPhrases.filter(ph => ph.id !== phrase.id));
    return _.shuffle([wrongAnswer, phrase]);
  }, [phrase]);

  const handleOptionsOverlayClick = () => {
    setIsOptionsOverlayShown(false);
  };

  return (
    <>
      <div className="phrases-exercise__content">
        <PhrasesExerciseText value={phrase.text} audio={phrase.textAudio} autoplay />
        {isFirstPhrase && <PhrasesExerciseTaskDescription text="Выберете правильный перевод" />}
      </div>
      <div className="phrases-exercise__footer">
        {isOptionsOverlayShown && <PhrasesExerciseOptionsOverlay onClick={handleOptionsOverlayClick} />}
        {options.map(op => (
          <PhrasesExerciseOption
            key={op.id}
            value={_clearOptionText(op.textTranslation)}
            isCorrect={op.id === phrase.id}
            onCorrectClick={onRightAnswer}
          />
        ))}
      </div>
    </>
  );
};

const PhrasesExerciseTaskNativeToForeign: FC<{
  isFirstPhrase?: boolean
  phrase: Phrase
  allPhrases: Phrase[]
  onRightAnswer: () => void
}> = ({
  isFirstPhrase,
  phrase,
  allPhrases,
  onRightAnswer
}) => {
  const options = useOptionsFromPhrases(phrase, allPhrases);
  const [playAudio, { duration }] = useAudio(phrase.textAudio);
  const [isOptionsOverlayShown, setIsOptionsOverlayShown] = useState(true);

  useEffect(() => {
    setIsOptionsOverlayShown(true);
  }, [phrase]);

  const handleOptionsOverlayClick = () => {
    setIsOptionsOverlayShown(false);
  };

  return (
    <>
      <div className="phrases-exercise__content">
        <PhrasesExerciseText value={capitalize(phrase.textTranslation)} />
        {isFirstPhrase && <PhrasesExerciseTaskDescription text="Выберете правильный перевод" />}
      </div>
      <div className="phrases-exercise__footer">
        {isOptionsOverlayShown && <PhrasesExerciseOptionsOverlay onClick={handleOptionsOverlayClick} />}
        {options.map(op => (
          <PhrasesExerciseOption
            key={op.id}
            value={_clearOptionText(op.text)}
            isCorrect={op.id === phrase.id}
            onCorrectClick={onRightAnswer}
            valueAudio={{
              duration,
              play: playAudio
            }}
          />
        ))}
      </div>
    </>
  );
};

const PhrasesExerciseTaskListening: FC<{
  isFirstPhrase?: boolean
  isWord?: boolean
  phrase: Phrase
  allPhrases: Phrase[]
  onSuccess: () => void
}> = ({
  isFirstPhrase,
  isWord,
  phrase,
  allPhrases,
  onSuccess
}) => {
  const {
    isAudioPlaying,
    gapsIndex,
    textParts,
    shuffledTextParts,
    isFinished,
    handlePlayClick,
    handleGapOptionUse
  } = usePhrasesExerciseTaskListening(phrase, onSuccess, { isWord });
  const [isOptionsOverlayShown, setIsOptionsOverlayShown] = useState(true);
  const options = useOptionsFromPhrases(phrase, allPhrases);

  const partsValueCounts = textParts.reduce<{ [key: string]: number }>((result, part) => {
    const val = _clearOptionText(part.value);
    if (result[val] === undefined) {
      result[val] = 0;
    }
    result[val]++;
    return result;
  }, {});

  const partsValuesUsedCounts = textParts.reduce<{ [key: string]: number }>((result, part) => {
    const val = _clearOptionText(part.value);
    if (result[val] === undefined) {
      result[val] = 0;
    }
    if (part.index < gapsIndex) {
      result[val]++;
    }
    return result;
  }, {});

  const groupedShuffledTextParts: {
    index: number
    value: string
    count: number
    isCorrect: boolean
    isUsed: boolean
  }[] = [];
  for (const part of shuffledTextParts) {
    if (part.value == ' ') {
      continue;
    }

    const value = _clearOptionText(part.value);

    if (groupedShuffledTextParts.map(p => p.value).includes(value)) {
      continue;
    }
    const currentValue = textParts[gapsIndex] && _clearOptionText(textParts[gapsIndex].value);
    const count = partsValueCounts[value] - partsValuesUsedCounts[value] || 0;

    groupedShuffledTextParts.push({
      index: part.index,
      value,
      count,
      isUsed: count == 0,
      isCorrect: currentValue == value
    });
  }

  useEffect(() => {
    setIsOptionsOverlayShown(true);
  }, [phrase]);

  const handleOptionsOverlayClick = () => {
    setIsOptionsOverlayShown(false);
  };

  return (
    <>
      <div className="phrases-exercise__content">
        <div
          onClick={handlePlayClick}
          className={classNames(
            'phrases-exercise__button-play',
            { 'phrases-exercise__button-play--playing': isAudioPlaying }
          )}
        />
        {isWord && <div className="phrases-exercise__text">
          {textParts.map(part => (
            part.index < gapsIndex ? <React.Fragment key={part.index}>{part.value}</React.Fragment> : null
          ))}
        </div>}
        {!isWord && <div className="phrases-exercise__gap-placeholders">
          {textParts.map(part => (
            <ExerciseGapPlaceholder
              className={isWord ? 'phrases-exercise__gap-placeholder--letter' : undefined}
              key={part.index}
              value={part.value}
              isActive={part.index == gapsIndex}
              isValueShown={part.index < gapsIndex}
              isSuccess={isFinished}
            />
          ))}
        </div>}
        {isFirstPhrase && <PhrasesExerciseTaskDescription text="Прослушайте и составьте фразу" />}
      </div>
      {!isWord && <div className="phrases-exercise__footer phrases-exercise__gap-options">
        {groupedShuffledTextParts.map(part => {
          return <ExerciseGapOption
            key={part.index}
            value={part.value}
            count={part.count}
            isCorrect={part.isCorrect}
            isUsed={part.isUsed}
            onUse={handleGapOptionUse}
          />;
        })}
      </div>}
      {/* NOTE: тестово сделал аудирование на восприятние слова на слух, нужно вспомнить его перевод */}
      {isWord && <div className="phrases-exercise__footer">
        {isOptionsOverlayShown && <PhrasesExerciseOptionsOverlay onClick={handleOptionsOverlayClick} />}
        {options.map(op => (
          <PhrasesExerciseOption
            key={op.id}
            value={_clearOptionText(op.textTranslation)}
            isCorrect={op.id === phrase.id}
            onCorrectClick={onSuccess}
          />
        ))}
      </div>}
    </>
  );
};

const PhrasesExerciseTaskExamples: FC<{
  word: Word
  onNextClick: () => void
}> = ({
  word,
  onNextClick
}) => {
  const [isOptionsOverlayShown, setIsOptionsOverlayShown] = useState(true);
  const [isGapShown, setIsGapShown] = useState(false);
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0);
  const [taskTypeByWord, setTaskTypeByWord] = useState<{ [key: number]: ExamplesTaskSubtype }>({});
  const wordExample = word.examples[currentExampleIndex];

  const [playAudio, { duration }] = useAudio(wordExample.textAudio);

  const taskType = taskTypeByWord[word.id] || EXAMPLES_READING;
  const isReading = taskType == EXAMPLES_READING;

  useEffect(() => {
    const nextTastType = {
      [EXAMPLES_READING]: EXAMPLES_GAP_ENDING,
      [EXAMPLES_GAP_ENDING]: EXAMPLES_GAP_ALL,
      [EXAMPLES_GAP_ALL]: EXAMPLES_GAP_ALL
    }[taskTypeByWord[word.id]] as ExamplesTaskSubtype || EXAMPLES_READING;

    setTaskTypeByWord(value => ({ ...value, [word.id]: nextTastType }));
  }, [word]);

  useEffect(() => {
    setIsOptionsOverlayShown(true);
    setIsGapShown(false);
  }, [wordExample]);

  const handleOptionsOverlayClick = () => {
    setIsOptionsOverlayShown(false);
  };

  const handleNext = () => {
    if (currentExampleIndex == word.examples.length - 1) {
      onNextClick();
      setCurrentExampleIndex(0);
    } else {
      setCurrentExampleIndex(val => val + 1);
    }
  };

  const handleCorrectClick = () => {
    setIsGapShown(true);
  };

  const wordExampleTextParts = wordExample.text.split(' ').map((part) => {
    if (part.match(/\[.*?\]/)) {
      return { text: part.replace(/[[\]]/g, ''), isTarget: true };
    }
    return { text: part, isTarget: false };
  });
  const wordExampleText = wordExampleTextParts.map(tp => tp.text).join(' ');
  const targetText = wordExampleTextParts.find(tp => tp.isTarget)?.text;

  return (
    <>
      {wordExample.question && <div className="phrases-exercise__question">
        <PhrasesExerciseText
          value={wordExample.question.text}
          // audio={wordExample.question.textAudio}
          // autoplay
        />
        <div className="phrases-exercise__translation">{capitalize(wordExample.question.textTranslation)}</div>
      </div>}
      <div className="phrases-exercise__content">
        {isReading && <PhrasesExerciseText
          value={`${wordExampleText} (${word.text})`}
          audio={wordExample.textAudio}
          autoplay
        />}
        {!isReading && <div className="phrases-exercise-text">
          {wordExampleTextParts.map((tp, i) => (
            <ExampleTextPart
              key={i}
              isGapShown={isGapShown}
              value={tp.text}
              isTarget={tp.isTarget}
              taskType={taskType}
            />
          ))}
        </div>}
        <div className="phrases-exercise__translation">{capitalize(wordExample.textTranslation)}</div>
      </div>
      <div className="phrases-exercise__footer">
        {!isReading && isOptionsOverlayShown && <PhrasesExerciseOptionsOverlay onClick={handleOptionsOverlayClick} />}
        {!isReading && <PhrasesExerciseOption
          value={targetText}
          isCorrect
          onCorrectClick={handleNext}
          onCorrectClickBeforeDelay={handleCorrectClick}
          valueAudio={{
            duration,
            play: playAudio
          }}
        />}
        {isReading && <Button color="secondary" isWide onClick={handleNext}>Далее</Button>}
      </div>
    </>
  );
};

const ExampleTextPart: FC<{
  isGapShown: boolean
  value: string
  isTarget: boolean
  taskType: ExamplesTaskSubtype
}> = ({
  isGapShown,
  value,
  isTarget,
  taskType
}) => {
  if (!isTarget) {
    return <span>{value}</span>;
  }
  if (isGapShown) {
    return <span className="phrases-exercise-text__highlighted-text">{value}</span>;

  } else if (taskType == EXAMPLES_GAP_ALL) {
    return <ExerciseGapPlaceholder
      isInline
      isActive
      value={value}
    />;

  } else if (taskType == EXAMPLES_GAP_ENDING) {
    return <span>
      {value.slice(0, Math.ceil(value.length / 2))}
      <span className="phrases-exercise-text__ending-gap phrases-exercise-text__ending-gap--current" />
    </span>;
  }
};

const PhrasesExerciseTaskRecording: FC<{
  isFirstPhrase?: boolean
  phrase: Phrase,
  onSuccess: () => void
}> = ({
  isFirstPhrase,
  phrase,
  onSuccess
}) => {
  const {
    isSuccess,
    isPlaying,
    isRecording,
    isRecordPlaying,
    handleRecordButtonClick
  } = usePhrasesExerciseTaskRecording(phrase, onSuccess);

  return (
    <>
      <div className="phrases-exercise__content">
        <PhrasesExerciseText
          value={phrase.text}
          audio={phrase.textAudio}
          isPlayDisabled={isRecording}
        />
        <div className="phrases-exercise__translation">{capitalize(phrase.textTranslation)}</div>
        {isFirstPhrase && <PhrasesExerciseTaskDescription text="Повторите фразу" />}
      </div>
      <div className="phrases-exercise__footer phrases-exercise__controls">
        {/* <div className="phrases-exercise__help-text">Произненсите фразу</div> */}
        <RecordButton
          onClick={handleRecordButtonClick}
          isSuccess={isSuccess || isRecordPlaying}
          isRecording={isRecording}
          isPulsing
          isDisabled={isPlaying}
        />
      </div>
    </>
  );
};

const PhrasesExerciseOption: FC<{
  valueAudio?: {
    duration: number
    play: () => void
  }
  value: string
  isCorrect?: boolean
  onCorrectClick: () => void
  onCorrectClickBeforeDelay?: () => void
}> = ({
  value,
  valueAudio,
  isCorrect,
  onCorrectClick,
  onCorrectClickBeforeDelay
}) => {
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [playSuccess] = useLocalAudio(require('audio/success.mp3').default);
  const [playFail] = useLocalAudio(require('audio/wrong.mp3').default);

  useEffect(() => {
    setIsFailed(false);
    setIsSuccess(false);
  }, [value, isCorrect]);

  useEffect(() => {
    if (isFailed) {
      const timerId = setTimeout(() => {
        setIsFailed(false);
      }, 400);
      return () => clearTimeout(timerId);
    }
  }, [isFailed]);

  const handleClick = () => {
    if (isCorrect && !isLocked) {
      onCorrectClickBeforeDelay && onCorrectClickBeforeDelay();
      setIsSuccess(true);
      if (valueAudio) {
        valueAudio.play();
      } else {
        // playSuccess();
      }
      setTimeout(() => {
        setIsSuccess(false);
        setIsLocked(false);
        onCorrectClick();
      }, valueAudio?.duration * 1000 || 400);
      setIsLocked(true);
    } else {
      // playFail();
      setIsFailed(true);
    }
  };

  return <Button
    color="default"
    isWide
    className={classNames(
      'phrases-exercise__option',
      {
        'phrases-exercise__option--failed': isFailed,
        'phrases-exercise__option--success': isSuccess
      }
    )}
    onClick={handleClick}
  >{value}</Button>;
};

export const PhrasesExerciseText: FC<{
  isPlayDisabled?: boolean
  autoplay?: boolean
  value: string
  className?: string
  audio?: string
}> = ({
  isPlayDisabled,
  autoplay,
  value,
  className,
  audio,
}) => {
  const [playAudio, { isPlaying: isAudioPlaying, stop: stopAudio }] = useAudio(audio);

  useEffect(() => {
    if (autoplay) {
      playAudio();
    }
  }, [value]);

  const handlePlayClick = () => {
    if (!audio || isPlayDisabled) {
      return;
    }
    if (isAudioPlaying) {
      stopAudio();
    } else {
      playAudio();
    }
  };

  return (
    <div
      className={classNames(
        'phrases-exercise-text',
        className,
        {
          'phrases-exercise-text--play-disabled': isPlayDisabled,
          'phrases-exercise-text--playable': Boolean(audio),
          'phrases-exercise-text--playing': isAudioPlaying
        }
      )}
      onClick={handlePlayClick}
    >
      {audio && <IconSpeaker className="phrases-exercise-text__icon" />}
      {capitalize(value)}
    </div>
  );
};

const PhrasesExerciseTaskDescription: FC<{ text: string }> = ({ text }) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleCloseClick = () => {
    setIsClosed(true);
  };

  if (isClosed) return null;

  return (
    <div className="phrases-exercise-task-description">
      <div className="phrases-exercise-task-description__inner">
        <div className="phrases-exercise-task-description__close" onClick={handleCloseClick} />
        <div className="phrases-exercise-task-description__title h2 gap-b-05x">Задание</div>
        <div className="phrases-exercise-task-description__text">{text}</div>
      </div>
    </div>
  );
};

const PhrasesExerciseOptionsOverlay: FC<{
  onClick: () => void
}> = ({
  onClick
}) => {
  return (
    <div className="phrases-exercise__options-overlay" onClick={onClick}>
      <div className="phrases-exercise__options-overlay-button">
        <IconShow color={COLOR_NEUTRAL_0} />
      </div>
    </div>
  );
};

const _clearOptionText = (value: string): string => {
  for (const key of ['?', ',']) {
    value = value.replace(key, '');
  }
  return value;
};

export default PhrasesExercise;
