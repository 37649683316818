import React, { FC } from 'react';

import Modal from 'components/Modal';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';

import './Confirm.less';

const Confirm: FC<{
  emoji?: string
  text: string
  note?: string
  confirmButtonText?: string
  onConfirmClick: () => void
  onCancelClick: () => void
}> = ({
  text,
  note,
  emoji,
  confirmButtonText = 'Да',
  onCancelClick,
  onConfirmClick
}) => {
  return (
    <Modal className="confirm" isOpen position="middle">
      {emoji && <div className="confirm__icon">{emoji}</div>}
      <div className="confirm__text h1">{text}</div>
      {note && <div className="confirm__note">{note}</div>}
      <ButtonGroup direction="row">
        <Button isWide primary onClick={onConfirmClick}>{confirmButtonText}</Button>
        <Button isWide onClick={onCancelClick}>Отмена</Button>
      </ButtonGroup>
    </Modal>
  );
};

export default Confirm;
