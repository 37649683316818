import React, { FC, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { completeSocialAuth } from 'authentication';

const SocialAuth: FC = () => {
  const location = useLocation();
  const { backend } = useParams<{ backend: string }>();

  useEffect(() => {
    completeSocialAuth(backend, location.search)
      .then(() => window.location.href = '/');
  }, []);

  return <div />;
};

export default SocialAuth;
