import React, { FC } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import classNames from 'classnames';

import urls, { reverse } from 'urls';
import { COLOR_SECONDARY } from 'const';

import { IconBook, IconProfile, IconStudy } from 'components/svg';

import './Navbar.less';

const Navbar: FC<{
  isVocabularyShown?: boolean
}> = ({
  isVocabularyShown = true
}) => {
  const { pathname } = useLocation();

  return (
    <div className="navbar">
      <NavLink to="/" className="navbar__item">
        <span className="navbar__item-icon">
          <IconStudy
            color={pathname === '/' ? COLOR_SECONDARY : undefined}
            isFilled={pathname === '/'}
          />
        </span>Упражнения
      </NavLink>
      {isVocabularyShown && <NavLink
        to={reverse(urls.vocabulary.index)}
        className={({ isActive }) => classNames(
          'navbar__item',
          { 'active': isActive }
        )}
      >
        <span>
          <IconBook
            color={pathname === reverse(urls.vocabulary.index) ? COLOR_SECONDARY : undefined}
            isFilled={pathname === reverse(urls.vocabulary.index)}
          />
        </span>Словарь
      </NavLink>}
      <NavLink
        to={reverse(urls.profile)}
        className={({ isActive }) => classNames(
          'navbar__item',
          { 'active': isActive }
        )}
      >
        <span>
          <IconProfile
            color={pathname === reverse(urls.profile) ? COLOR_SECONDARY : undefined}
            isFilled={pathname === reverse(urls.profile)}
          />
        </span>
        Профиль
      </NavLink>
    </div>
  );
};

export default Navbar;
