import React, { FC, useState } from 'react';
import { tokenAuth, setToken } from 'authentication';

const AdminLogin: FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleFormSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    tokenAuth(username, password).then((response) => {
      setToken(response.token);
      window.location.href = '/';
    });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <input
        type="text"
        name="username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button type="submit">Login</button>
    </form>
  );
};

export default AdminLogin;
