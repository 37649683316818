import React, { FC, SVGProps } from 'react';
import { COLOR_PRIMARY } from 'const';

const IconStudy: FC<SVGProps<SVGSVGElement> & {
  isFilled?: boolean
}> = ({
  isFilled,
  color = COLOR_PRIMARY
}) => {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.16667 17.66V10L13.1667 14L21.1667 10V17.66L13.1667 21.5L5.16667 17.66Z" stroke={color} strokeWidth="2" strokeLinejoin="round"/>
      <path d="M1.16667 8L13.1667 2L25.1667 8L13.1667 14L1.16667 8Z" stroke={color} fill={isFilled ? color : undefined} strokeWidth="2" strokeLinejoin="round"/>
      <path d="M25.1667 8V17" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default IconStudy;
