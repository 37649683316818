import React, { FC } from 'react';
import Confirm from './Confirm';

const ExerciseExitModal: FC<{
  onConfirmClick: () => void
  onCancelClick: () => void
}> = ({
  onConfirmClick,
  onCancelClick
}) => {
  return <Confirm
    text="Уверены что хотите выйти?"
    note="Прогресс не будет сохранен!"
    emoji="😧"
    onConfirmClick={onConfirmClick}
    onCancelClick={onCancelClick}
  />;
};

export default ExerciseExitModal;
