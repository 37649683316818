import _ from 'lodash';
import { useEffect, useState } from 'react';

import type * as client from 'client';

import { WORD_TYPE_NOUN } from 'const';
import { useSelector } from 'hooks';
import { getSubtopicById } from 'store/selectors';
import { findSameBeginning, getRandomItems } from 'utils';

// Kui <adjective> <noun: object>
// Q: Kui vana sa oled?
// A: Ma olen 34 aastat vana
// Q: Kui vana <pronoun> <olema>?
// A: <pronoun> <olema> <number: 20-40> aastat vana

// Q: Kuhu <pronoun | name> <verb>
// A: <pronoun | name> <verb> <noun: place>

// Ma lähen busijaama<?>
// Ma lähe<?> busijaamasse
// Ma lähe<?> busijaama<?>
// M<?> l<?> b<?>
// <?> <?> <?>

// 1. Есть констуркция
// 2. Конструкция состоит из частей речи (dsl?)
// 3. Генерация фразы на основе конструкции исходя из параметров

const pronounsTranslations: { [Key: string]: string } = {
  'mina': 'я',
  'sina': 'ты',
  'tema': 'он/она/оно',
  'meie': 'мы',
  'teie': 'вы',
  'nemad': 'они',
  'ma': 'я',
  'sa': 'ты',
  'ta': 'он/она/оно',
  'me': 'мы',
  'te': 'вы',
  'nad': 'они',

  'ma ei': 'я не',
  'sa ei': 'ты не',
  'ta ei': 'он/она/оно не',
  'me ei': 'мы не',
  'te ei': 'вы не',
  'nad ei': 'они не'
};

const verbEndingsByPronouns: { [Key: string]: string } = {
  'mina': 'n',
  'sina': 'd',
  'tema': 'b',
  'meie': 'me',
  'teie': 'te',
  'nemad': 'vad',
  'ma': 'n',
  'sa': 'd',
  'ta': 'b',
  'me': 'me',
  'te': 'te',
  'nad': 'vad',
  'ma ei': '',
  'sa ei': '',
  'ta ei': '',
  'me ei': '',
  'te ei': '',
  'nad ei': ''
};

const olemaFormsByPronouns: { [Key: string]: string } = {
  'mina': 'olen',
  'ma': 'olen',
  'sina': 'oled',
  'sa': 'oled',
  'tema': 'on',
  'ta': 'on',
  'meie': 'oleme',
  'me': 'oleme',
  'teie': 'olete',
  'te': 'olete',
  'nemad': 'on',
  'nad': 'on',

  'ma ei': 'ole',
  'sa ei': 'ole',
  'ta ei': 'ole',
  'me ei': 'ole',
  'te ei': 'ole',
  'nad ei': 'ole'
};

const allOlemaForms = [...new Set(Object.values(olemaFormsByPronouns))];

const allVerbEndings = [...new Set(Object.values(verbEndingsByPronouns))];

const verbs = [
  { text: 'tee', translation: 'делать' },
  { text: 'ärka', translation: 'просыпаться'},
  { text: 'loe', translation: 'читать'},
  { text: 'õpi', translation: 'учить'},
  { text: 'mängi', translation: 'играть'},
  { text: 'puhka', translation: 'отдыхать'},
  { text: 'maga', translation: 'спать'},
  { text: 'pese', translation: 'мыть'},
  { text: 'treeni', translation: 'тренироваться'},
  { text: 'tööta', translation: 'работать'},
  { text: 'joo', translation: 'пить'},
  { text: 'söö', translation: 'есть'},
  { text: 'maksa', translation: 'платить'},
];

const adjectives = [
  { text: 'hea', translation: 'хороший'},
  { text: 'halb', translation: 'плохой'},
  { text: 'ilus', translation: 'красивый'},
  { text: 'inetu', translation: 'уродливый'},
  { text: 'suur', translation: 'большой'},
  { text: 'väike', translation: 'маленький'},
  { text: 'kõrge', translation: 'высокий'},
  { text: 'madal', translation: 'низкий'},
  { text: 'tume', translation: 'темный'},
  { text: 'hele', translation: 'светлый'},
  { text: 'kiire', translation: 'быстрый'},
  { text: 'aeglane', translation: 'медленный'},
  { text: 'kuum', translation: 'горячий'},
  { text: 'külm', translation: 'холодный'},
  { text: 'vana', translation: 'старый'},
  { text: 'uus', translation: 'новый'},
  { text: 'kallis', translation: 'дорогой'},
  { text: 'odav', translation: 'дешевый'},
  { text: 'rõõmus', translation: 'счастливый'},
  { text: 'kurb', translation: 'грустный'},
];

const pronouns = Object.keys(verbEndingsByPronouns);

const getPronounTranslation = (pronoun: string) => {
  const translationParts = pronounsTranslations[pronoun].split('/');
  return _.sample(translationParts);
};

export type TextPart = {
  text: string
  gap?: Gap
}

export type Gap = {
  visibleText?: string  // NOTE: it is used for endings
  hiddenText: string
  wrongOptions: string[]
}

type GapOption = {
  isCorrect: boolean
  value: string
}

export const DIFFICULTY_LEVEL_EASY = 'EASY';
export const DIFFICULTY_LEVEL_MEDIUM = 'MEDIUM';
export const DIFFICULTY_LEVEL_HARD = 'HARD';

export type DifficultyLevelType = 
  typeof DIFFICULTY_LEVEL_EASY |
  typeof DIFFICULTY_LEVEL_MEDIUM |
  typeof DIFFICULTY_LEVEL_HARD;

const useVerbConjugationCardFactory = () => {
  return () => {
    const pronoun = _.sample(pronouns);
    const verb = _.sample(verbs);
    const correctEnding = verbEndingsByPronouns[pronoun];

    return {
      textParts: [
        { text: pronoun },
        {
          text: verb.text,
          gap: {
            visibleText: verb.text,
            hiddenText: correctEnding,
            wrongOptions: allVerbEndings
          }
        }
      ],
      textTranslation: `${pronounsTranslations[pronoun]} | ${verb.translation}`
    };
    //   return {
    //     textParts: [
    //       { text: `${_.capitalize(pronoun)} ${verb.text}` },
    //       { text: correctEnding, isGap: true, isEnding: true }
    //     ],
    //     options: _.shuffle([
    //       { isCorrect: false, value: getRandomItems<string>(allVerbEndings, 1, [correctEnding])[0] },
    //       { isCorrect: true, value: correctEnding }
    //     ]),
    //     translation: `${_.capitalize(pronounsTranslations[pronoun])} | ${verb.translation}`
    //   };
    // };
  };
};

const useOlemaVerbCardFactory = () => {
  return () => {
    const pronoun = _.sample(pronouns);
    const adjective = _.sample(adjectives);
    const correctOlemaForm = olemaFormsByPronouns[pronoun];

    return {
      textParts: [
        { text: pronoun },
        {
          text: correctOlemaForm,
          gap: {
            hiddenText: correctOlemaForm,
            wrongOptions: allOlemaForms
          }
        },
        { text: adjective.text }
      ],
      textTranslation: `${pronounsTranslations[pronoun]} | ${adjective.translation}`
    };
    // return {
    //   textParts: [
    //     { text: _.capitalize(pronoun) },
    //     { text: correctOlemaForm, isGap: true },
    //     { text: adjective.text }
    //   ],
    //   options: _.shuffle([
    //     { isCorrect: false, value: getRandomItems<string>(allOlemaForms, 1, [correctOlemaForm])[0] },
    //     { isCorrect: true, value: correctOlemaForm }
    //   ]),
    //   translation: `${_.capitalize(pronounsTranslations[pronoun])} | ${adjective.translation}`
    // };
  };
};

const PLACES_CATEGORY_ID = 14;

const useKuhuCardFactory = (
  subtopicId: string,
  difficultyLevel: DifficultyLevelType = DIFFICULTY_LEVEL_HARD
) => {
  const subtopic = useSelector(state => getSubtopicById(state, Number(subtopicId)));
  const places = subtopic.relatedWords.filter(
    w => w.wordType == WORD_TYPE_NOUN
    && w.categoryId == PLACES_CATEGORY_ID
    && w.extra?.textNounForm2
    && w.extra?.kuhu
  );
  const allNounEndings = ['s', 'st', 'l', 'lt', 'sse', 'le'];
  const allVerbEngings = ['n', 'd', 'b', 'me', 'te', 'vad'];
  const allPronouns = ['ma', 'sa', 'ta', 'me', 'te', 'nad'];
  const verbTranslationByVerb: { [Key: string]: { [Key: string]: string } } = {
    'lähe': {
      'n': 'иду',
      'd': 'идешь',
      'b': 'идет',
      'me': 'идем',
      'te': 'идете',
      'vad': 'идут'
    },
    'sõida': {
      'n': 'еду',
      'd': 'едешь',
      'b': 'едет',
      'me': 'едем',
      'te': 'едите',
      'vad': 'едут'
    }
  };

  return () => {
    const word = _.sample(places);
    let correctForm = word.extra.kuhu;
    if (correctForm.startsWith('+')) {
      correctForm = correctForm.replace('+', word.extra.textNounForm2);
    }
    const wordBasePart = findSameBeginning(word.text, correctForm);
    const correctEnding = correctForm.replace(wordBasePart, '');
    const clues: client.types.Word[] = [word];
    const questionPronoun = _.sample(allPronouns);
    const verbBase = _.sample(['lähe', 'sõida']);

    const answerPronoun = { 'sa': 'ma', 'te': 'me', 'ma': 'ta' }[questionPronoun] || questionPronoun;
    const answerVerbEnding = verbEndingsByPronouns[answerPronoun];
    const answerVerb = `${verbBase}${answerVerbEnding}`;
    const answerNoun = `${wordBasePart}${correctEnding}`;
    const answerTranslation = `${getPronounTranslation(answerPronoun)} ${verbTranslationByVerb[verbBase][answerVerbEnding]} ...`;

    const questionVerbEnding = verbEndingsByPronouns[questionPronoun];
    const questionTranslation = `Куда ${getPronounTranslation(questionPronoun)} ${verbTranslationByVerb[verbBase][questionVerbEnding]}?`;

    // jaluta - гулять
    // lähe - to go
    // lenda
    // ratsuta
    // sõida

    let textParts: TextPart[];
    if (difficultyLevel == DIFFICULTY_LEVEL_EASY) {
      textParts = [
        { text: answerPronoun },
        {
          text: answerVerb,
          gap: {
            visibleText: verbBase,
            hiddenText: answerVerbEnding,
            wrongOptions: allVerbEngings
          }
        },
        {
          text: word.text,
          gap: {
            visibleText: wordBasePart,
            hiddenText: correctEnding,
            wrongOptions: allNounEndings
          }
        }
      ];
    } else if (difficultyLevel == DIFFICULTY_LEVEL_MEDIUM) {
      textParts = [
        {
          text: answerPronoun,
          gap: {
            visibleText: answerPronoun.slice(0, 1),
            hiddenText: answerPronoun.slice(1),
            wrongOptions: allPronouns.map(p => p.slice(1))
          }
        },
        {
          text: answerVerb,
          gap: {
            visibleText: answerVerb.slice(0, 1),
            hiddenText: answerVerb.slice(1),
            wrongOptions: allVerbEngings.map(en => `${verbBase}${en}`.slice(1))
          }
        },
        {
          text: word.text,
          gap: {
            visibleText: answerNoun.slice(0, 1),
            hiddenText: answerNoun.slice(1),
            wrongOptions: allNounEndings.map(en => `${wordBasePart}${en}`.slice(1))
          }
        }
      ];
    } else if (difficultyLevel == DIFFICULTY_LEVEL_HARD) {
      textParts = [
        {
          text: answerPronoun,
          gap: {
            hiddenText: answerPronoun,
            wrongOptions: allPronouns
          }
        },
        {
          text: answerVerb,
          gap: {
            hiddenText: answerVerb,
            wrongOptions: allVerbEngings.map(en => `${verbBase}${en}`)
          }
        },
        {
          text: word.text,
          gap: {
            hiddenText: answerNoun,
            wrongOptions: allNounEndings.map(en => `${wordBasePart}${en}`)
          }
        }
      ];
    }
    // rule: '<форма 2> + sse/le | <исключение>',
    return {
      question: {
        text: `Kuhu ${questionPronoun} ${verbBase}${questionVerbEnding}?`,
        textTranslation: questionTranslation
      },
      clues: clues,
      textTranslation: answerTranslation,
      textParts
    };
  };
};

// TODO: update according to the new logic
const usePluralEndingExercise = (subtopicId: string) => {
  const subtopic = useSelector(state => getSubtopicById(state, Number(subtopicId)));

  const wrongEndings = ['ed', 'id', 'ad', 'od', 'd'];
  const words = getRandomItems(subtopic.relatedWords.filter(
    w => w.wordType == WORD_TYPE_NOUN
    && w.extra?.plural
    && w.extra?.textNounForm2
  ), 7, []);

  const makeCard = () => {
    const word = words[Math.floor(Math.random() * words.length)];

    const wordBasePart = findSameBeginning(word.text, word.extra.textNounForm2);
    const pluralForm = word.extra.plural.replace('+', word.extra.textNounForm2);
    const correctEnding = pluralForm.replace(wordBasePart, '');
    const wrongEnding = getRandomItems(wrongEndings, 1, [correctEnding])[0];

    return {
      textParts: [
        {
          text: `${word.text} → ${wordBasePart}`,
          isGap: false,
          isEnding: false
        },
        {
          text: correctEnding,
          isGap: true,
          isEnding: true
        }
      ],
      options: _.shuffle([
        { isCorrect: true, value: correctEnding },
        { isCorrect: false, value: wrongEnding }
      ]),
      translation: _.capitalize(word.textTranslation),
      rule: '<форма 2> + d'
    };
  };

  return {
    makeCard
  };
};

// TODO: update according to the new logic
const usePluralWithNumberEndingExercise = (subtopicId: string) => {
  const subtopic = useSelector(state => getSubtopicById(state, Number(subtopicId)));
  const words = getRandomItems(subtopic.relatedWords.filter(
    w => w.wordType == WORD_TYPE_NOUN
    && w.extra?.textNounForm3
  ), 7, []);
  const wrongEndings = ['ed', 'id', 'ad', 'od', 'd'];
  const numbers = [
    'kaheksa',
    'kaks',
    'neli',
    'kümme',
    'kuus',
    'üks',
    'kolm',
    'seitse',
    'viis',
    'üheksa',
  ];

  const makeCard = () => {
    const number = numbers[Math.floor(Math.random() * words.length)];
    const word = words[Math.floor(Math.random() * words.length)];
    const wordBasePart = findSameBeginning(word.text, word.extra.textNounForm3);
    const pluralForm = number == 'üks' ? word.text : word.extra.textNounForm3;
    const correctEnding = pluralForm.replace(wordBasePart, '');
    const wrongEnding = getRandomItems(wrongEndings, 1, [correctEnding])[0];

    return {
      textParts: [
        {
          text: `${word.text} → ${number} ${wordBasePart}`,
          isGap: false,
          isEnding: false
        },
        {
          text: correctEnding,
          isGap: true,
          isEnding: true
        }
      ],
      options: _.shuffle([
        { isCorrect: true, value: correctEnding },
        { isCorrect: false, value: wrongEnding }
      ]),
      translation: _.capitalize(word.textTranslation),
      rule: '<форма 1> → <число> <форма 3>'
    };
  };

  return {
    makeCard
  };
};

const useGrammarCard = (cardFactory: () => {
  textParts: TextPart[]
  textTranslation?: string
  textAudio?: string
  clues?: client.types.Word[]
  question?: {
    text: string
    textTranslation: string
  }
}) => {
  const [card, setCard] = useState(() => cardFactory());
  const [isFinished, setIsFinished] = useState(false);

  const { textParts, next, options, allOptions } = useGrammarGaps(
    card.textParts,
    { onFinish: () => setIsFinished(true) }
  );

  const refresh = () => {
    setCard(cardFactory());
    setIsFinished(false);
  };

  return {
    textParts,
    textAudio: card.textAudio,
    textTranslation: card.textTranslation,
    clues: card.clues,
    question: card.question,
    options,
    allOptions,
    isFinished,
    next,
    refresh
  };
};

const useGrammarGaps = (textParts: TextPart[], params: { onFinish: () => void }) => {
  const [isFinished, setIsFinished] = useState(false);
  const [currentGapIndex, setCurrentGapIndex] = useState<number>();
  const [allOptions, setAllOptions] = useState<{
    gapIndex: number
    value: string
    isUsed: boolean
    isCurrent: boolean
  }[]>([]);
  const [options, setOptions] = useState<GapOption[]>([]);

  useEffect(() => {
    // NOTE: the textParts are updated before the currentGapIndex, so we need to check
    // if threre is a gap, otherwise we will get an error.
    if (currentGapIndex === undefined || !textParts[currentGapIndex]?.gap) {
      return;
    }
    const gap = textParts[currentGapIndex].gap;
    setOptions(_.shuffle([
      { isCorrect: false, value: getRandomItems<string>(gap.wrongOptions, 1, [gap.hiddenText])[0] },
      { isCorrect: true, value: gap.hiddenText }
    ]));

    setAllOptions(allOptions.map(opt => ({
      ...opt,
      isUsed: opt.gapIndex < currentGapIndex,
      isCurrent: opt.gapIndex == currentGapIndex
    })));
  }, [textParts, currentGapIndex]);

  useEffect(() => {
    setIsFinished(false);
    setCurrentGapIndex(textParts.findIndex(tp => Boolean(tp.gap)));
    setAllOptions(_.shuffle(
      textParts.map((tp, index) => ({
        gapIndex: index,
        value: tp.gap?.hiddenText,
        isUsed: false,
        isCurrent: false
      })).filter(op => Boolean(op.value))
    ));
  }, [textParts]);

  const next = () => {
    if (isFinished) {
      return;
    }
    const nextGapIndex = textParts
      .map((tp, index) => ({ index, isGap: Boolean(tp.gap) }))
      .findIndex(tp => tp.isGap && tp.index > currentGapIndex);

    if (nextGapIndex > 0) {
      setCurrentGapIndex(nextGapIndex);
    } else {
      setIsFinished(true);
      params.onFinish();
    }
  };

  return {
    next,
    options,
    allOptions,
    textParts: textParts.map((tp, index) => {
      if (!tp.gap) {
        return { text: tp.text };
      }
      return {
        text: tp.text,
        gap: {
          visibleText: tp.gap.visibleText,
          hiddenText: tp.gap.hiddenText,
          isCurrent: index === currentGapIndex,
          isShown: isFinished || index < currentGapIndex
        }
      };
    })
  };
};

export {
  useGrammarCard,
  useKuhuCardFactory,
  useOlemaVerbCardFactory,
  usePluralEndingExercise,
  usePluralWithNumberEndingExercise,
  useVerbConjugationCardFactory
};
