import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import type { Word } from 'client/types';

import Modal from 'components/Modal';
import Button from 'components/Button';
import VocabularyWord from 'components/VocabularyWord/VocabularyWord';

import './WordsToLearnModal.less';
import { useConfig } from 'v2/hooks';

const WordsToLearnModal: FC<{
  title: string
  words: Word[]
  choosenCategoryId?: number
  categories?: {
    id: number
    title: string
  }[]
  wordsLimit?: number
  onCloseClick: () => void
  onBackClick?: () => void
  onStartClick: (words: Word[]) => void
  onWordRemoved?: (wordId: number) => void
  onCategoryChange?: (categoryId: number) => void
}> = ({
  title,
  categories,
  choosenCategoryId,
  words,
  wordsLimit = 7,
  onCloseClick,
  onBackClick,
  onStartClick,
  onCategoryChange,
  onWordRemoved
}) => {
  const [removedWordsIds, setRemovedWordsIds] = useState<number[]>([]);
  // const [currentWordsLimit, setCurrentWordsLimit] = useState(wordsLimit);
  const {
    config: { wordsToLearnCount: currentWordsLimit },
    actions: { setMaxWordsToLearn, setMinWordsToLearn }
  } = useConfig();

  const wordsToLearn = words.filter(w => !removedWordsIds.includes(w.id)).slice(0, currentWordsLimit);
  const canRemove = words.filter(w => !removedWordsIds.includes(w.id)).length > currentWordsLimit;

  // useEffect(() => {
  //   if (words.length <= currentWordsLimit) {
  //     onStartClick(wordsToLearn);
  //   }
  // }, []);

  const handleRemoveClick = (wordId: number) => {
    if (!removedWordsIds.includes(wordId)) {
      onWordRemoved?.(wordId);
      setRemovedWordsIds([...removedWordsIds, wordId]);
    }
  };

  // if (words.length <= currentWordsLimit) {
  //   return null;
  // }

  return (
    <Modal
      isOpen
      title={title}
      onCloseClick={onCloseClick}
      onBackClick={onBackClick}
      className="vocabulary-words-modal"
    >
      <div className="vocabulary-words-modal-count-setter">
        <div
          className={classNames(
            'vocabulary-words-modal-count-setter__item',
            { 'active': currentWordsLimit == 4 }
          )}
          onClick={setMinWordsToLearn}
        >Меньше</div>
        <div
          className={classNames(
            'vocabulary-words-modal-count-setter__item',
            { 'active': currentWordsLimit == wordsLimit }
          )}
          onClick={setMaxWordsToLearn}
        >Больше</div>
      </div>
      {categories && <select
        className="vocabulary-words-modal__category-select"
        value={choosenCategoryId}
        onChange={e => onCategoryChange?.(Number(e.target.value))}
      >
        {categories.map(category => (
          <option
            key={category.id}
            value={category.id}
          >{category.title}</option>
        ))}
      </select>}
      <div className="vocabulary-words-modal__words">
        {wordsToLearn.map(word => <VocabularyWord
          key={word.id}
          text={word.text}
          translation={word.textTranslation}
          onRemove={canRemove ? () => handleRemoveClick(word.id) : undefined}
        />)}
      </div>
      <Button
        isWide
        color="secondary"
        onClick={() => onStartClick(wordsToLearn)}
        className="vocabulary-words-modal__button"
      >Далее</Button>
    </Modal>
  );
};

export default WordsToLearnModal;
