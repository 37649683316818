import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useFetchSubtopicGrammarQuiz } from 'client/hooks';
import { useGrammarCard } from 'components/screens/GrammarExercise/hooks';
import { useQuizCardFactory, useQuizCountdown } from './hooks';

import type { GrammarQuizGapType } from 'const';
import {
  GRAMMAR_QUIZ_GAP_TYPE_ALL_HIDDEN,
  GRAMMAR_QUIZ_GAP_TYPE_ENDING,
  GRAMMAR_QUIZ_GAP_TYPE_FIRST_LETTER
} from 'const';

import Loading from 'components/Loading';
import GrammarExercise from 'components/screens/GrammarExercise';

import type { GrammarQuiz } from 'client/types';
import { useAudio } from 'v2/contexts/AudioContext';
import urls, { reverse } from 'v2/urls';
import ExerciseEnd from 'components/ExerciseEnd';
import { getNumberCaptionRU } from 'utils';

const GrammarQuizOrLoader: FC = () => {
  const { subtopicId, quizType } = useParams();
  const { isLoading, error, grammarQuiz } = useFetchSubtopicGrammarQuiz(Number(subtopicId), quizType);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <>{error}</>;
  }

  return <GrammarQuiz grammarQuiz={grammarQuiz} />;
};

const GRAMMAR_QUIZ_DURATION_SECONDS = 10 * 60;

const GrammarQuiz: FC<{ grammarQuiz: GrammarQuiz }> = ({ grammarQuiz }) => {
  const { subtopicId } = useParams();
  const navigate = useNavigate();

  const [passedCardsCount, setPassedCardsCount] = useState(0);
  const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
  const [isExerciseFinished, setIsExerciseFinished] = useState(false);

  let gapType: GrammarQuizGapType;
  if (passedCardsCount < 4) {
    gapType = GRAMMAR_QUIZ_GAP_TYPE_ENDING;
  } else if (passedCardsCount < 8) {
    gapType = GRAMMAR_QUIZ_GAP_TYPE_FIRST_LETTER;
  } else {
    gapType = GRAMMAR_QUIZ_GAP_TYPE_ALL_HIDDEN;
  }
  const cardFactory = useQuizCardFactory(grammarQuiz.sentences, gapType, grammarQuiz.respectOrder);
  const {
    question,
    clues,
    textParts,
    textTranslation,
    textAudio,
    next,
    options,
    isFinished,
    refresh
  } = useGrammarCard(cardFactory);

  const [playAudio] = useAudio(textAudio, { onStop: refresh });

  const {
    progress,
    countdownLabel,
    resetCountdown
  } = useQuizCountdown(GRAMMAR_QUIZ_DURATION_SECONDS, () => setIsExerciseFinished(true));

  useEffect(() => {
    if (isFinished) {
      setPassedCardsCount(value => value + 1);
      if (textAudio) {
        playAudio();
      } else {
        const timerId = setTimeout(() => {
          refresh();
        }, 1200);
        return () => clearTimeout(timerId);
      }
    }
  }, [isFinished]);

  const handleExit = () => {
    navigate(reverse(urls.subtopic, { subtopicId }));
  };

  const handleRepeatExerciseClick = () => {
    setIsExerciseFinished(false);
    resetCountdown();
    setPassedCardsCount(0);
    setWrongAnswersCount(0);
    refresh();
  };

  const passedCardsCountCaption = getNumberCaptionRU(passedCardsCount, ['карточка', 'карточки', 'карточек']);
  const wrongAnswersCountCaption = getNumberCaptionRU(wrongAnswersCount, ['раз', 'раза', 'раз']);

  if (isExerciseFinished) {
    return <ExerciseEnd
      title={grammarQuiz.quizType}
      emoji="🚀"
      note={`Вы прошли ${passedCardsCount} ${passedCardsCountCaption} и ответили неправильно ${wrongAnswersCount} ${wrongAnswersCountCaption}`}
      text="Время вышло!"
      isExerciseFinished
      onRepeatExerciseClick={handleRepeatExerciseClick}
      onExitConfirmClick={handleExit}
    />;
  }

  const handleWrongAnswer = () => {
    setWrongAnswersCount(value => value + 1);
  };

  return <GrammarExercise
    progressLabel={countdownLabel}
    progress={progress}
    isOptionsOverlayShown
    // isOptionsOverlayShown={passedCardsCount > 4}
    question={question}
    clues={clues}
    textParts={textParts}
    // rule={card.rule}
    translation={textTranslation}
    options={options}
    onExit={handleExit}
    onCorrectOptionClick={next}
    onWrongOptionClick={handleWrongAnswer}
  />;
};

export default GrammarQuizOrLoader;
