import React, { FC } from 'react';
import classNames from 'classnames';

import { IconMicrophone, IconCheck } from 'components/svg';

import './RecordButton.less';

const RecordButton: FC<{
  className?: string
  isRecording?: boolean
  isSuccess?: boolean
  isPulsing?: boolean
  isDisabled?: boolean
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}> = ({
  className,
  isDisabled,
  isRecording,
  isSuccess,
  isPulsing,
  onClick
}) => {
  let icon;
  if (isSuccess) {
    icon = <IconCheck color="#fff" />;
  } else if (isRecording) {
    icon = <IconRecording />;
  } else {
    icon = <IconMicrophone color="#fff" />;
  }
  return (
    <div
      className={classNames(
        'record-button',
        className,
        {
          'record-button--disabled': isDisabled,
          'record-button--recoding': isRecording,
          'record-button--success': isSuccess,
          'record-button--pulsing': isPulsing && !isRecording && !isDisabled && !isSuccess
        }
      )}
      onClick={onClick}
    >
      {icon}
    </div>
  );
};

const IconRecording: FC = () => {
  return (
    <div className="icon-recording">
      <div className="icon-recording__item icon-recording__item-1" />
      <div className="icon-recording__item icon-recording__item-2" />
      <div className="icon-recording__item icon-recording__item-3" />
      <div className="icon-recording__item icon-recording__item-4" />
      <div className="icon-recording__item icon-recording__item-5" />
      <div className="icon-recording__item icon-recording__item-6" />
    </div>
  );
};

export default RecordButton;
