import axios from 'axios';
import { SocialAuthResponse } from 'client/types';
import apiUrls from 'client/urls';

type SocialAuthUrlResponse = {
  url: string | null
  error: boolean
}

type TokenAuthResponse = {
  token: string
  error: boolean
}

export function isAuthenticated(): boolean {
  return Boolean(getToken());
}

export async function getSocialAuthUrl(backend: string): Promise<SocialAuthUrlResponse> {
  try {
    const response = await fetch(apiUrls.socialAuthBegin(backend));
    if ((400 <= response.status) && (response.status < 500)) {
      return { url: null, error: true };
    }
    const { url } = await response.json();
    return { url, error: false };
  } catch (error) {
    console.log(error);
  }
}

export async function completeSocialAuth(backend: string, queryString: string): Promise<SocialAuthResponse> {
  try {
    const response = await axios.post<SocialAuthResponse>(apiUrls.socialAuthComplete(backend, queryString));
    setToken(response.data.token);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export function removeToken(): void {
  localStorage.removeItem('token');
}

export function setToken(token: string): void {
  localStorage.setItem('token', token);
}

export function hasToken(): boolean {
  return !!getToken();
}

export function getToken(): string {
  return localStorage.getItem('token');
}

export function getAuthHeader(): { [Key: string]: string } {
  return { Authorization: `Token ${getToken()}` };
}

export async function tokenAuth(username: string, password: string): Promise<TokenAuthResponse> {
  try {
    const response = await fetch(apiUrls.tokenAuth(), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    });
    if ((400 <= response.status) && (response.status < 500)) {
      return { token: null, error: true };
    }
    const { token } = await response.json();
    return { token, error: false };
  } catch (error) {
    console.log(error);
  }
}

// export async function tokenSocialAuth(backend: string, queryString: string): Promise<AuthResponse> {
//   queryString = queryString.startsWith('?') ? queryString : `?${queryString}`;
//   try {
//     const response = await fetch(`${apiUrls.socialAuthComplete(backend)}${queryString}`);
//     if ((400 <= response.status) && (response.status < 500)) {
//       return { token: null, error: true };
//     }
//     const { token } = await response.json();
//     return { token, error: false };
//   } catch (error) {
//     console.log(error);
//   }
// }
