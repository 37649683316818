import React, { FC } from 'react';
import classnames from 'classnames';

import './Label.less';

const Label: FC<{
  highlight?: number
  className?: string
  children: React.ReactNode
}> & {
  Box: typeof LabelBox
} = ({
  highlight = 0,
  children,
  className
}) => {
  return <div className={classnames(
    'label',
    className,
    { 'label--highlight-1': highlight === 1 }
  )}>{children}</div>;
};

const LabelBox: FC<{
  className?: string
  children: React.ReactNode
}> = ({
  className,
  children
}) => {
  return <div className={classnames('label-box', className)}>{children}</div>;
};

Label.Box = LabelBox;

export default Label;
