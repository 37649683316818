import _ from 'lodash';
import { useEffect, useState } from 'react';

import { GrammarQuizSentence, GrammarQuizWord } from 'client/types';
import type { GrammarQuizGapType } from 'const';
import {
  GRAMMAR_QUIZ_GAP_TYPE_ALL_HIDDEN,
  GRAMMAR_QUIZ_GAP_TYPE_ENDING,
  GRAMMAR_QUIZ_GAP_TYPE_FIRST_LETTER
} from 'const';


export const useQuizCardFactory = (
  sentences: GrammarQuizSentence[],
  gapType: GrammarQuizGapType,
  respectOrder: boolean = false
) => {
  const [usedTexts, setUsedTexts] = useState<string[]>([]);

  useEffect(() => {
    if (usedTexts.length === sentences.length) {
      setUsedTexts([]);
    }
  }, [usedTexts, sentences]);

  return () => {
    const _sentences = sentences.filter(sentence => !usedTexts.includes(sentence.text));
    const sentence = respectOrder ? _sentences[0] : _.sample(_sentences);
    // const sentence = _.sample(sentences.filter(sentence => !usedTexts.includes(sentence.text)));
    setUsedTexts([...usedTexts, sentence.text]);
    // gapType = sentence.gapTypes.includes(gapType) ? gapType : sentence.gapTypes[0];
    return {
      textAudio: sentence.textAudio,
      textTranslation: sentence.textTranslation,
      textParts: sentence.words.map(word => makeTextPartFromQuizWord(word, gapType))
    };
  };
};

const makeTextPartFromQuizWord = (quizWord: GrammarQuizWord, gapType: GrammarQuizGapType) => {
  const { text, wrongText } = quizWord;

  if (!quizWord.gapTypes.includes(gapType)) {
    return { text };
  }

  switch (gapType) {
    case GRAMMAR_QUIZ_GAP_TYPE_ENDING: {
      let base = text;
      let ending;
      let wrongEnding;

      for (let i = 0; Math.min(text.length, wrongText.length); i++) {
        if (text[i] != wrongText[i]) {
          break;
        }
        base = text.slice(null, i + 1);
        ending = text.slice(i + 1) || null;
        wrongEnding = wrongText.slice(i + 1) || null;
      }

      return {
        text,
        gap: {
          visibleText: base,
          hiddenText: ending,
          wrongOptions: [wrongEnding]
        }
      };
    }
  
    case GRAMMAR_QUIZ_GAP_TYPE_FIRST_LETTER: {
      return {
        text,
        gap: {
          visibleText: text.slice(0, 1),
          hiddenText: text,
          wrongOptions: [wrongText]
        }
      };
    }

    case GRAMMAR_QUIZ_GAP_TYPE_ALL_HIDDEN: {
      return {
        text,
        gap: {
          hiddenText: text,
          wrongOptions: [wrongText]
        }
      };
    }
  
    default:
      return { text };
  }
};

export const useQuizCountdown = (durationSeconds: number, onTimeIsUp: () => void) => {
  const [countdown, setCountdown] = useState(durationSeconds);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCountdown(value => {
        if (value > 0) {
          return value - 1;
        }
        clearInterval(timerId);
        onTimeIsUp();
        return value;
      });
    }, 1000);
    return () => clearInterval(timerId);
  }, [countdown > 0]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;
  const countdownLabel = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  const progress = Math.floor((durationSeconds - countdown) / durationSeconds * 100);

  return {
    countdown,
    progress,
    countdownLabel,
    resetCountdown: () => setCountdown(durationSeconds)
  };
};
