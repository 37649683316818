import React, { FC, createContext, useContext, useEffect, useState } from 'react';

import { useFetchCurrentUser, useFetchInitData, useFetchTopics, useFetchVocabulary } from 'client/hooks';
import type { Topic, User, Vocabulary, Init2Data } from 'client/types';

type ContextValue = {
  isLoading: boolean
  // isReady: boolean
  isAuthenticated: boolean
  user?: User
  topics?: Topic[]
  vocabulary?: Vocabulary
  numbers?: Init2Data['numbers']
}

const AppContext = createContext<ContextValue>({
  isAuthenticated: false,
  isLoading: true,
  // isReady: false
});

export const AppProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoading: isUserLoading, user } = useFetchCurrentUser();
  const { isLoading: isInitDataLoading, initData } =  useFetchInitData();
  // const { isLoading: isTopicsLoading, topics } = useFetchTopics();
  // const { isLoading: isVocabularyLoading, vocabulary } = useFetchVocabulary();
  // const isLoading = isUserLoading || isTopicsLoading || isVocabularyLoading;
  const isLoading = isUserLoading || isInitDataLoading;
  const { vocabulary, topics, numbers } = initData || {};

  // const [isReady, setIsReady] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isAuthenticated = !isLoading && Boolean(user);

  // useEffect(() => {
  //   if (!isLoading && user) {
  //     setIsReady(true);
  //   }
  // }, [isLoading, user]);

  return (
    <AppContext.Provider value={{
      isLoading,
      // isReady,
      isAuthenticated,
      user,
      vocabulary,
      topics,
      numbers
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
