import classNames from 'classnames';
import React, { FC, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { IconArrow } from 'components/svg';

import './Modal.less';

type Props = {
  title?: string
  isOpen: boolean
  className?: string
  position?: 'middle' | 'bottom'
  children?: React.ReactNode
  onCloseClick?: (event: React.MouseEvent<HTMLElement>) => void
  onBackClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const modalRoot = document.getElementById('modal');

const ModalContainer: FC<Props> & {
  Buttons: typeof ModalButtons
  Text: typeof ModalText
} = (props) => {
  if (!props.isOpen) {
    return null;
  }
  return <Modal {...props} />;
};

const Modal: FC<Props> = ({
  title,
  children,
  className,
  position = 'bottom',
  onCloseClick,
  onBackClick
}) => {
  const [container] = useState(() => {
    return document.createElement('div');
  });

  useEffect(() => {
    modalRoot.appendChild(container);
    return () => {
      modalRoot.removeChild(container);
    };
  });

  return ReactDOM.createPortal((
    <div className={`modal-overlay modal-overlay--position-${position}`}>
      <div className={classNames('modal', `modal--position-${position}`, className)}>
        <div className="modal__wrapper">
          <div className="modal__inner">
            {(onBackClick || onCloseClick || title) && <div className="modal__header">
              {(onBackClick) && <div
                className="modal__back-button"
                onClick={onBackClick}
              >
                <IconArrow className="modal__back-button-icon" />
              </div>}
              <div className="modal__title">{title}</div>
              <div className="modal__close" onClick={onCloseClick} />
            </div>}
            <div className="modal__content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  ), container);
};

const ModalButtons: FC<{ noWrap?: boolean, children?: React.ReactNode }> = ({ children, noWrap }) => {
  return <div className={classNames(
    'modal__buttons',
    { 'modal__buttons--no-wrap': noWrap }
  )}>{children}</div>;
};

const ModalText: FC<{
  dangerouslySetInnerHTML?: { __html: string },
  children?: React.ReactNode
}> = ({ children, dangerouslySetInnerHTML }) => {
  return <div
    className="modal__text"
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
  >{children}</div>;
};

ModalContainer.Buttons = ModalButtons;
ModalContainer.Text = ModalText;

export default ModalContainer;
