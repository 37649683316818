import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ExerciseTopbar from 'components/ExerciseTopbar';
import Layout from 'components/Layout';

import { useApp } from 'v2/contexts/AppContext';
import { useAudio } from 'v2/contexts/AudioContext';
import classNames from 'classnames';
import { PhrasesExerciseText } from 'components/screens/PhrasesExercise/PhrasesExercise';

import { useQuizCountdown } from 'v2/routes/GrammarQuiz/hooks';

import ExerciseEnd from 'components/ExerciseEnd';
import { getNumberCaptionRU } from 'utils';
import urls, { reverse } from 'v2/urls';

import './NumbersQuiz.less';
import Topbar from 'components/Topbar';
import Button from 'components/Button';

type _Number = {
  value: number
  text: string
  textAudio: string
}

const NumbersQuiz: FC = () => {
  const { subtopicId } = useParams();
  const { numbers: { simple: numbers } } = useApp();
  // const numbers: _Number[] = [
  //   {
  //     value: 42,
  //     text: 'Naljakümment kaks',
  //     textAudio: 'https://monsterteach.com/media/mteach/word/2d/b0/2db0bb551e762592875831d5b89da7fd.mp3'
  //   },
  //   {
  //     value: 50,
  //     text: 'Viiskümment',
  //     textAudio: 'https://monsterteach.com/media/mteach/word/f3/25/f325bff5fe98da0a489ccfd460fc2771.mp3'
  //   }
  // ];

  const numbersGroups = [
    { min: 0, max: 10 },
    { min: 11, max: 19 },
    { min: 20, max: 99},
    { min: 11, max: 999999}
  ];

  const [currentNumber, setCurrentNumber] = useState<_Number>(() => _.sample(
    numbers.filter(num => num.value >= numbersGroups[0].min && num.value <= numbersGroups[0].max))
  );
  const [usedNumbers, setUsedNumbers] = useState<number[]>([]);
  const [enteredValue, setEnteredValue] = useState('');
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isTextShown, setIsTextShown] = useState(false);
  const [isExerciseFinished, setIsExerciseFinished] = useState(false);
  const [passedCardsCount, setPassedCardsCount] = useState(0);
  const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
  const [currentNumbersGroupIndex, setCurrentNumbersGroupIndex] = useState(0);

  const [playAudio] = useAudio(currentNumber?.textAudio);
  const navigate = useNavigate();

  const {
    progress,
    countdownLabel,
    // resetCountdown
  } = useQuizCountdown(10 * 60, () => setIsExerciseFinished(true));

  const isKeyboardDisabled = enteredValue.length === (String(currentNumber?.value) || '').length;

  const currentNumbersGroup = numbersGroups[currentNumbersGroupIndex] || numbersGroups[numbersGroups.length - 1];

  useEffect(() => {
    if (progress > 60) {
      setCurrentNumbersGroupIndex(3);
    } else if (progress > 40) {
      setCurrentNumbersGroupIndex(2);
    } else if (progress > 20) {
      setCurrentNumbersGroupIndex(1);
    }
  }, [progress]);

  useEffect(() => {
    if (isFailed) {
      const timerId = setTimeout(() => {
        setIsFailed(false);
      }, 200);
      return () => clearTimeout(timerId);
    }
  }, [isFailed]);

  useEffect(() => {
    if (!currentNumber) {
      return;
    }
    // Success
    if (enteredValue == String(currentNumber.value)) {
      setIsSuccess(true);
      setPassedCardsCount(value => value + 1);
      const timerId = setTimeout(() => {
        setCurrentNumber(_.sample(
          numbers.filter(num => !usedNumbers.includes(num.value)
            && num.value >= currentNumbersGroup.min
            && num.value <= currentNumbersGroup.max)
        ));
        setIsSuccess(false);
        setEnteredValue('');
      }, 500);
      return () => clearTimeout(timerId);
    }
  }, [enteredValue, currentNumber]);

  useEffect(() => {
    if (isStarted) {
      setUsedNumbers(usedNumbers => [...usedNumbers, currentNumber.value]);
      playAudio();
    }
  }, [isStarted, currentNumber]);

  useEffect(() => {
    const isAllNumbersUsed = numbers.filter(num => !usedNumbers.includes(num.value)
      && num.value >= currentNumbersGroup.min
      && num.value <= currentNumbersGroup.max).length === 0;
    // numbers.length && usedNumbers.length == numbers.length
    if (isAllNumbersUsed) {
      setUsedNumbers([]);
    }
  }, [usedNumbers, numbers]);

  const handleKeyboardClick = (value: string) => {
    if (isKeyboardDisabled) {
      return;
    }
    const expectedValue = String(currentNumber.value);
    if (expectedValue.slice(0, enteredValue.length + 1) === `${enteredValue}${value}`) {
      setEnteredValue(`${enteredValue}${value}`);
    } else {
      setIsFailed(true);
      playAudio();
      setWrongAnswersCount(value => value + 1);
    }
  };

  const handleExit = () => {
    navigate(reverse(urls.subtopic, { subtopicId }));
  };

  const passedCardsCountCaption = getNumberCaptionRU(passedCardsCount, ['карточка', 'карточки', 'карточек']);
  const wrongAnswersCountCaption = getNumberCaptionRU(wrongAnswersCount, ['раз', 'раза', 'раз']);

  if (!isStarted) {
    return <NumbersQuizInto
      backLink={reverse(urls.subtopic, { subtopicId })}
      title="Numbers Quiz"
      onStartClick={() => setIsStarted(true)}
    />;
  }

  if (isExerciseFinished) {
    return <ExerciseEnd
      title="Numbers Quiz"
      emoji="🚀"
      note={`Вы прошли ${passedCardsCount} ${passedCardsCountCaption} и ответили неправильно ${wrongAnswersCount} ${wrongAnswersCountCaption}`}
      text="Время вышло!"
      isExerciseFinished
      // onRepeatExerciseClick={handleRepeatExerciseClick}
      onExitConfirmClick={handleExit}
    />;
  }

  return (
    <Layout
      noScroll
      isBgWhite
      className="numbers-quiz"
    >
      <Layout.Header>
        <ExerciseTopbar
          progress={progress}
          label={countdownLabel}
          onCloseClick={handleExit}
        />
      </Layout.Header>
      <Layout.Row className="numbers-quiz__inner">
        <div className="numbers-quiz__config">
          <label className="form-checkbox">
            <input type="checkbox" checked={isTextShown} onChange={() => setIsTextShown(val => !val)} />
            <span className="form-checkbox__label">
              Показывать текст
              <span className="form-checkbox__toggler"></span>
            </span>
          </label>
        </div>
        <div className="numbers-quiz__content">
          {isTextShown ? <PhrasesExerciseText
            className="numbers-quiz__text"
            value={currentNumber.text}
            audio={currentNumber.textAudio}
          /> : <PlayButton audio={currentNumber.textAudio} />}
        </div>
        <div className="numbers-quiz__bottom">
          <div
            className={classNames('numbers-quiz__typing-value', { 'numbers-quiz__typing-value--success': isSuccess })}
          >{enteredValue || <span style={{ color: '#fff' }}>0</span>}</div>
          <div className="numbers-quiz-keyboard">
            {[...Array(9).keys()].map(i => (
              <div
                key={i}
                className={classNames('numbers-quiz-keyboard__number', { 'numbers-quiz-keyboard__number--failed': isFailed })}
                onClick={() => handleKeyboardClick(String(i + 1))}
              >{i + 1}</div>
            ))}
            <div
              className={classNames('numbers-quiz-keyboard__number', { 'numbers-quiz-keyboard__number--failed': isFailed })}
              onClick={() => handleKeyboardClick('0')}
            >0</div>
          </div>
        </div>
      </Layout.Row>
    </Layout>
  );
};

const PlayButton: FC<{
  audio: string
}> = ({
  audio
}) => {
  const [playAudio, { isPlaying: isAudioPlaying }] = useAudio(audio);

  return (
    <div
      onClick={playAudio}
      className={classNames(
        'numbers-quiz-button-play',
        { 'numbers-quiz-button-play--playing': isAudioPlaying }
      )}
    />
  );
};

const NumbersQuizInto: FC<{
  backLink: string
  title: string
  onStartClick: () => void
}> = ({
  backLink,
  title,
  onStartClick
}) => {
  return (
    <Layout
      className="numbers-quiz-intro"
      noScroll
      isBgWhite
    >
      <Layout.Header>
        <Topbar
          backLink={backLink}
          title={title}
        />
      </Layout.Header>
      <Layout.Row className="numbers-quiz-intro__content">
        <div />
      </Layout.Row>
      <Layout.Footer>
        <Layout.Row>
          <Button color="secondary" isWide onClick={onStartClick}>Начать упражнение!</Button>
        </Layout.Row>
      </Layout.Footer>
    </Layout>
  );
};

export default NumbersQuiz;
