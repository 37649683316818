import React, { FC } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Layout from 'components/Layout';
import Navbar from 'components/Navbar';
import CourseBlock from 'components/CourseBlock';
import RadialProgressBar from 'components/RadialProgressBar';
import Loading from 'components/Loading';
import Label from 'components/Label';

import urls, { reverse } from 'urls';

import { useCurrentCourse } from './hooks';

import { IconLock } from 'components/svg';
import { COLOR_NEUTRAL_500 } from 'const';

import './Course.less';

// const subtopics = [
//   { emoji: '🚌', progress: 100, isLocked: false, title: 'Общественный транспорт' },
//   { emoji: '🏦', progress: 50, isLocked: false, title: 'В банке' },
//   { emoji: '👬', progress: 10, isLocked: false, title: 'Встреча с другом' },
//   { emoji: '🛍️', progress: 0, isLocked: true, title: 'В магазине' },
// ];

const Course: FC = () => {
  const { isLoading, course } = useCurrentCourse();

  if (isLoading) {
    return <Loading />;
  }

  if (!course) {
    return <Navigate to={urls.courseSelection} />;
  }

  const { topics } = course;

  return (
    <Layout className="course">
      <Layout.Header isWhite>
        <CourseBlock />
      </Layout.Header>
      <Layout.Row>
        {/* <div className="course__subtitle"><Label>Темы</Label></div> */}
        {topics.map(topic => (
          <div
            key={topic.id}
            className={classNames('course-topic paper', { 'course-topic--locked': topic.isLocked })}
          >
            <div className="course-topic__head">
              <div className="course-topic__title h1">{topic.title}</div>
              {topic.isLocked ?
                <IconLock color={COLOR_NEUTRAL_500} /> :
                <Label className="course-topic__progress">{topic.progress}%</Label>
              }
            </div>
            <div className="course-topic__subtopics">
              {topic.subtopics.map((subtopic, idx) => (
                <CourseTopicSubtopic
                  key={subtopic.id}
                  id={subtopic.id}
                  progress={subtopic.progress}
                  emoji={subtopic.emoji}
                  title={subtopic.title}
                  isLocked={subtopic.isLocked}
                  noFloat={idx === 0 && topic.subtopics.length > 3}
                />
              ))}
            </div>
          </div>
        ))}
      </Layout.Row>
      <Layout.Footer>
        <Layout.Row>
          <Navbar />
        </Layout.Row>
      </Layout.Footer>
    </Layout>
  );
};

export const CourseTopicSubtopic: FC<{
  id: number
  progress: number
  emoji: string
  title: string
  noFloat?: boolean
  isLocked?: boolean
}> = ({
  id,
  progress,
  emoji,
  title,
  noFloat,
  isLocked,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (isLocked) return;
    navigate(reverse(urls.subtopic, { subtopicId: id }));
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(
        'course-topic-subtopic',
        {
          'course-topic-subtopic--no-float': noFloat,
          'course-topic-subtopic--completed': progress >= 100,
          'course-topic-subtopic--locked': isLocked
        }
      )}
    >
      {!isLocked && <RadialProgressBar
        progress={progress}
        strokeWidth={6}
        size={80}
        className="course-topic-subtopic__progressbar"
      >
        <div className="course-topic-subtopic__icon">
          <div className="course-topic-subtopic__icon-text">{emoji}</div>
        </div>
      </RadialProgressBar>}
      {isLocked && <div className="course-topic-subtopic__icon">
        <div className="course-topic-subtopic__icon-text">{emoji}</div>
      </div>}
      <div className="course-topic-subtopic__title">{title}</div>
    </div>
  );
};

export default Course;
