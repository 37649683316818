import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logoutUser } from 'store/user/actions';

import Layout from 'components/Layout';
import Navbar from 'components/Navbar';
import Topbar from 'components/Topbar';
import CourseBlock from 'components/CourseBlock';

import urls, { reverse } from 'urls';
import { sendMessageToMobileApp } from 'utils';
import { removeToken } from 'authentication';
import { MOBILE_APP_EVENT_USER_LOGGED_OUT } from 'const';

import './Profile.less';

const Profile: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogoutButtonClick = useCallback(() => {
    sendMessageToMobileApp(MOBILE_APP_EVENT_USER_LOGGED_OUT);
    // TODO: убрать нативный confirm
    if (confirm('Вы уверены что хотите выйти?')) {
      // Mobile app channel
      sendMessageToMobileApp(MOBILE_APP_EVENT_USER_LOGGED_OUT);
      dispatch(logoutUser());
      navigate('/');
    }
  }, []);

  const handleCourseEditButtonClick = () => {
    navigate(
      reverse(urls.courseSelection),
      { state: { backLink: reverse(urls.profile) } }
    );
  };

  return (
    <Layout className="profile">
      <Layout.Header isWhite>
        <Topbar title="🥷 Мой профиль" />
      </Layout.Header>
      <Layout.Row>
        <div className="h2 gap-b-1x profile-course-title">
          Текущий курс
          <div
            onClick={handleCourseEditButtonClick}
            className="profile-course-title__edit-button"
          >Изменить</div>
        </div>
        <CourseBlock className="paper gap-b-05x" />
        {/* TODO: подтягивать статистику */}
        {/* <div className="profile__course-summaries gap-b-2x">
          <div className="profile-course-summary paper gap-r-05x">
            <div className="profile-course-summary__title">Пройдено слов</div>
            <div className="profile-course-summary__number">42</div>
          </div>
          <div className="profile-course-summary paper">
            <div className="profile-course-summary__title">Пройдено фраз</div>
            <div className="profile-course-summary__number">24</div>
          </div>
        </div> */}
        <div className="h2 gap-b-1x gap-t-2x">Настройки</div>
        <div className="profile-settings paper">
          <div className="profile-settings__item" onClick={handleLogoutButtonClick}>
            Выйти из системы
          </div>
        </div>
      </Layout.Row>
      <Layout.Footer>
        <Layout.Row>
          <Navbar />
        </Layout.Row>
      </Layout.Footer>
    </Layout>
  );
};

export const SimpleProfile: FC = () => {
  const handleLogoutButtonClick = useCallback(() => {
    sendMessageToMobileApp(MOBILE_APP_EVENT_USER_LOGGED_OUT);
    // TODO: убрать нативный confirm
    if (confirm('Вы уверены что хотите выйти?')) {
      // Mobile app channel
      sendMessageToMobileApp(MOBILE_APP_EVENT_USER_LOGGED_OUT);
      removeToken();
      window.location.href = '/';
    }
  }, []);

  return (
    <Layout className="profile">
      <Layout.Header isWhite>
        <Topbar title="🥷 Мой профиль" />
      </Layout.Header>
      <Layout.Row>
        <div className="h2 gap-b-1x gap-t-2x">Настройки</div>
        <div className="profile-settings paper">
          <div className="profile-settings__item" onClick={handleLogoutButtonClick}>
            Выйти из системы
          </div>
        </div>
      </Layout.Row>
      <Layout.Footer>
        <Layout.Row>
          <Navbar isVocabularyShown={false} />
        </Layout.Row>
      </Layout.Footer>
    </Layout>
  );
};

export default Profile;
