import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
// import { useLocalAudio } from 'hooks';

import './ExerciseGapOption.less';

const ExerciseGapOption: FC<{
  isCorrect?: boolean
  isUsed?: boolean
  count?: number
  size?: 'normal' | 'large'
  value: string
  className?: string
  onUse: () => void
  onWrong?: () => void
}> = ({
  isUsed,
  isCorrect,
  size,
  count = 1,
  value,
  className,
  onUse,
  onWrong
}) => {
  const [isFailed, setIsFailed] = useState(false);
  // const [playFail] = useLocalAudio(require('audio/wrong.mp3').default);

  useEffect(() => {
    if (isFailed) {
      const timerId = setTimeout(() => {
        setIsFailed(false);
      }, 200);
      return () => clearTimeout(timerId);
    }
  }, [isFailed]);

  const handleClick = () => {
    if (isUsed) return;
    if (isCorrect) {
      onUse();
    } else {
      onWrong?.();
      // playFail();
      setIsFailed(true);
    }
  };

  return (
    <div
      data-number={count > 1 ? count : undefined}
      className={classNames(
        'exercise-gap-option',
        className,
        {
          [`exercise-gap-option--size-${size}`]: size,
          'exercise-gap-option--used': isUsed,
          'exercise-gap-option--failed': isFailed
        }
      )}
      onClick={handleClick}
    >
      {value}
    </div>
  );
};

export default ExerciseGapOption;
