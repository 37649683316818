import React, { FC, useState } from 'react';

import Layout from 'components/Layout';
import Topbar from 'components/Topbar';
import Button from 'components/Button';

import './ExerciseEnd.less';
import ExerciseExitModal from 'components/ExerciseExitModal';

const ExerciseEnd: FC<{
  title: string
  emoji: string
  text: string
  note?: string
  isExerciseFinished?: boolean
  onRepeatExerciseClick?: () => void
  onRepeatClick?: (event: React.MouseEvent<HTMLElement>) => void
  onNextTaskClick?: (event: React.MouseEvent<HTMLElement>) => void
  onNextExerciseClick?: (event: React.MouseEvent<HTMLElement>) => void
  onExitConfirmClick?: () => void
  onSkipClick?: (event: React.MouseEvent<HTMLElement>) => void
}> = ({
  title,
  emoji,
  text,
  note,
  isExerciseFinished,
  onRepeatClick,
  onRepeatExerciseClick,
  onExitConfirmClick,
  onNextExerciseClick,
  onNextTaskClick,
}) => {
  const [isExitModalOpen, setIsExistModalOpen] = useState(false);

  const handleExitCancelClick = () => {
    setIsExistModalOpen(false);
  };

  const handleExitClick = () => {
    if (isExerciseFinished) {
      onExitConfirmClick();
    }
    setIsExistModalOpen(true);
  };

  return (
    <Layout
      isBgWhite
      noScroll
      className="exercise-end"
    >
      <Layout.Header>
        <Topbar title={title} onCloseClick={handleExitClick} />
      </Layout.Header>
      {isExitModalOpen && <ExerciseExitModal
        onCancelClick={handleExitCancelClick}
        onConfirmClick={onExitConfirmClick}
      />}
      <div className="exercise-end__content">
        <div className="exercise-end__icon gap-b-2x">{emoji}</div>
        <div className="exercise-end__h1 gap-b-1x">{text}</div>
        {note && <div className="exercise-end__text">{note}</div>}
      </div>
      <div className="exercise-end__buttons">
        {onRepeatClick && <Button isWide onClick={onRepeatClick}>Повторить задание</Button>}
        {onRepeatExerciseClick && <Button isWide onClick={onRepeatExerciseClick}>Повторить упражнение</Button>}
        {isExerciseFinished && <Button isWide onClick={onExitConfirmClick}>Закончить заниматься</Button>}
        {onNextExerciseClick && <Button isWide color="secondary" onClick={onNextExerciseClick}>Следующее упражнение</Button>}
        {onNextTaskClick && <Button isWide color="secondary" onClick={onNextTaskClick}>Следующее задание</Button>}
      </div>
    </Layout>
  );
};

export default ExerciseEnd;
