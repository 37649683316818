export default {
  profile: '/profile/',
  socialAuth: '/social-auth/:backend/',
  numbersExercise: '/subtopics/:subtopicId/numbers-exercise/',
  subtopic: '/subtopics/:subtopicId/',
  subtopicGrammarExercise: '/subtopics/:subtopicId/grammar/:quizType/',
  subtopicWords: '/subtopics/:subtopicId/words/',
  subtopicWordsExercise: '/subtopics/:subtopicId/words/exercise/',
};

export const reverse = (url: string, params?: { [Key: string]: string | number }): string => {
  if (!params) {
    return url;
  }
  for (const key in params) {
    url = url.replace(`:${key}`, String(params[key]));
  }
  return url;
};
