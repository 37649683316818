import { usePreviousValue } from 'hooks';
import React, { FC, createContext, useContext, useEffect, useRef, useState } from 'react';

type _Params = {
  src?: string,
  options?: {
    onStop?: () => void,
    withProgress?: boolean
  }
}

type AudioContextValue = {
  isPlaying: boolean
  progress: number
  duration: number
  play: () => void
  stop: () => void
  setParams: (params: _Params) => void
}

type UseAudioReturnValue = [
  () => void,
  {
    stop: () => void
    isPlaying: boolean
    progress: number
    duration: number
  }
]

const AudioContext = createContext<AudioContextValue>(null);

export const AudioPorvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const audio = useRef<HTMLAudioElement>(null);

  const [params, setParams] = useState<_Params>({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const { options, src } = params;

  useEffect(() => {
    const audioElem = audio.current;
    if (isPlaying) {
      audioElem.currentTime = 0;
      audioElem.play().catch((error) => {
        console.error(error);
      });
    } else {
      audioElem.pause();
      audioElem.currentTime = 0;
    }
  }, [src, isPlaying]);

  const play = () => {
    setIsPlaying(true);
  };

  const stop = () => {
    setIsPlaying(false);
  };

  const onEnded = () => {
    setIsPlaying(false);
    setProgress(100);
  };

  const prevIsPlaying = usePreviousValue(isPlaying);
  const prevSrc = usePreviousValue(src);

  useEffect(() => {
    if (!isPlaying && prevIsPlaying && prevSrc === src) {
      options?.onStop && options.onStop();
    }
  }, [isPlaying]);

  useEffect(() => {
    const audioElem = audio.current;

    // const onPause = () => setIsPlaying(false);
    // const onPlay = () => setIsPlaying(true);
    const onPlaying = () => {
      if (options?.withProgress) {
        setProgress(Math.ceil(audioElem.currentTime / audioElem.duration * 100));
      }
    };
    const onCanPlay = () => {};

    const onLoadedMetadata = () => {
      setDuration(audioElem.duration);
    };

    audioElem.addEventListener('ended', onEnded);
    audioElem.addEventListener('timeupdate', onPlaying);
    audioElem.addEventListener('loadeddata', onCanPlay);
    audioElem.addEventListener('loadedmetadata', onLoadedMetadata);
    // audioElem.addEventListener('pause', onPause);
    // audioElem.addEventListener('play', onPlay);

    return () => {
      audioElem.removeEventListener('ended', onEnded);
      audioElem.removeEventListener('timeupdate', onPlaying);
      audioElem.removeEventListener('loadeddata', onCanPlay);
      audioElem.removeEventListener('loadedmetadata', onLoadedMetadata);
      // audioElem.removeEventListener('pause', onPause);
      // audioElem.removeEventListener('play', onPlay);
    };
  }, []);

  return (
    <AudioContext.Provider value={{
      play,
      stop,
      isPlaying,
      duration,
      progress,
      setParams
    }}>
      <audio preload="metadata" ref={audio} src={src} />
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = (
  src: string,
  options?: {
    onStop?: () => void,
    withProgress?: boolean
  }
): UseAudioReturnValue => {
  const { play: play_, stop, isPlaying, duration, progress, setParams } = useContext(AudioContext);

  const play = () => {
    if (src) {
      setParams({
        src,
        options
      });
      play_();
    }
  };

  return [play, {
    isPlaying,
    progress,
    stop,
    duration
  }];
};
