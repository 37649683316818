import React, { FC, MouseEvent, useState } from 'react';

import Layout from 'components/Layout';
import Button from 'components/Button';
import Topbar from 'components/Topbar';
import Chip from 'components/Chip';
import Modal from 'components/Modal';
import { IconMicrophone, IconHeadphones } from 'components/svg';

import { getNumberCaptionRU } from 'utils';

import './ExerciseInfo.less';

const ExerciseInfo: FC<{
  exerciseType: {
    name: string,
    icon: string
  }
  title: string
  backLink?: string
  isMicrophoneShown?: boolean
  isHeadphonesShown?: boolean
  tasks?: { text: string }[]
  onBackClick?: () => void
  onStartClick: (event: MouseEvent<HTMLElement>) => void
  onSkipClick?: (event: MouseEvent<HTMLElement>) => void
}> = ({
  title,
  backLink,
  exerciseType,
  isHeadphonesShown,
  isMicrophoneShown,
  tasks,
  onStartClick,
  onSkipClick,
  onBackClick
}) => {
  const [isTasksModalOpen, setIsTaskModalOpen] = useState(false);

  const handleTasksButtonClick = () => {
    setIsTaskModalOpen(true);
  };

  const handleTasksModalCloseClick = () => {
    setIsTaskModalOpen(false);
  };

  return (
    <Layout
      className="exercise-info"
      noScroll
      isBgWhite
    >
      <Layout.Header>
        <Topbar
          backLink={backLink}
          title={title}
          onBackClick={onBackClick}
        />
      </Layout.Header>
      {isTasksModalOpen && <ExerciseInfoTasksModal
        tasks={tasks}
        onCloseClick={handleTasksModalCloseClick}
      />}
      <Layout.Row className="exercise-info__content">
        <div className="exercise-info__icon">{exerciseType.icon}</div>
        <div className="exercise-info__title">Упражнение "{exerciseType.name}"</div>
        {tasks && <Chip
          isActive
          className="gap-t-1x"
          onClick={handleTasksButtonClick}
        >
          {tasks.length} {getNumberCaptionRU(tasks.length, ['задание', 'задания', 'заданий'])}
        </Chip>}
        <div className="exercise-info__features">
          {isHeadphonesShown && <div className="exercise-info__feature">
            <div className="exercise-info__feature-icon">
              <IconHeadphones width={20} height={20} />
            </div>
            <div className="exercise-info__feature-text">
              Воспользуйтесь наушниками или увеличьте громкость
            </div>
          </div>}
          {isMicrophoneShown && <div className="exercise-info__feature">
            <div className="exercise-info__feature-icon">
              <IconMicrophone width={20} height={20} />
            </div>
            <div className="exercise-info__feature-text">
              Нужно будет говорить в микрофон
            </div>
          </div>}
        </div>
      </Layout.Row>
      <Layout.Footer>
        <Layout.Row>
          <Button color="secondary" isWide onClick={onStartClick}>Начать упражнение!</Button>
          {onSkipClick && <Button transparent isWide className="exercise-info__skip-button">
            Пропустить, не могу говорить
          </Button>}
        </Layout.Row>
      </Layout.Footer>
    </Layout>
  );
};

const ExerciseInfoTasksModal: FC<{
  tasks?: { text: string }[]
  onCloseClick: () => void
}> = ({
  tasks,
  onCloseClick
}) => {
  return (
    <Modal isOpen title="Задания" onCloseClick={onCloseClick} className="exercise-info-task-modal">
      {tasks && tasks.map((task, index) => (
        <div className="exercise-info-task-modal__task" key={index}>
          {/* <div className="exercise-info__task-label">{index + 1}</div> */}
          {index + 1}. {task.text}
        </div>
      ))}
    </Modal>
  );
};

export default ExerciseInfo;
