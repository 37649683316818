import React, { FC } from 'react';
import classNames from 'classnames';

import './ExerciseGapPlaceholder.less';

const ExerciseGapPlaceholder: FC<{
  className?: string
  isInline?: boolean
  isActive?: boolean
  isValueShown?: boolean
  isSuccess?: boolean
  num?: number
  value?: string
}> = ({
  className,
  isInline,
  isActive,
  isValueShown,
  isSuccess,
  num,
  value
}) => {
  return (
    <div className={classNames(
      'exercise-gap-placeholder',
      className,
      {
        'inline': isInline,
        'active': isActive && !isSuccess,
        'exercise-gap-placeholder--value-shown': isValueShown,
        'exercise-gap-placeholder--success': isSuccess
      }
    )}>
      {num && <div className="exercise-gap-placeholder__number">{num}</div>}
      {isValueShown && value}
    </div>
  );
};

export default ExerciseGapPlaceholder;
