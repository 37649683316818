import React, { FC } from 'react';
import classNames from 'classnames';

import Topbar from 'components/Topbar';
import Label from 'components/Label';

import './ExerciseTopbar.less';

const ExerciseTopbar: FC<{
  className?: string
  progress: number
  label?: string
  roundNumber?: number
  roundsCount?: number
  isTransparent?: boolean
  onCloseClick: (event: React.MouseEvent<HTMLElement>) => void
}> = ({
  className,
  progress,
  label,
  roundNumber,
  roundsCount,
  isTransparent,
  onCloseClick
}) => {
  return (
    <Topbar
      className={classNames('exercise-topbar', className, {'exercise-topbar--transparent': isTransparent})}
      onCloseClick={onCloseClick}
    >
      <div className="exercise-topbar__content">
        {label && <Label className="exercise-topbar__round">{label}</Label>}
        {roundNumber && <Label className="exercise-topbar__round">Задание {roundNumber} / {roundsCount}</Label>}
        <div className="exercise-topbar__progress-bar">
          <span style={{ width: `${progress}%` }} />
        </div>
      </div>
    </Topbar>
  );
};

export default ExerciseTopbar;
