import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import './Layout.less';

type Props = {
  className?: string
  isBgWhite?: boolean
  isHeaderWhite?: boolean
  noScroll?: boolean
  bgGrey?: boolean
  hasHeader?: boolean
  hasNavbar?: boolean
  children: ReactNode
  header?: ReactNode
  footer?: ReactNode
}

const Layout: FC<Props> & {
  Header: typeof LayoutHeader
  Row: typeof LayoutRow
  Footer: typeof LayoutFooter
} = ({
  children,
  className,
  noScroll,
  isBgWhite,
  isHeaderWhite,
  header,
  footer
}) => {
  // https://stackoverflow.com/questions/55464194/how-to-define-typescript-for-react-children-map
  // https://javascript.plainenglish.io/everything-you-need-to-know-about-children-prop-in-react-9c0e0913f5a2
  let rawHeader: ReactNode | undefined;
  let rawFooter: ReactNode | undefined;
  children = React.Children.map(children, (child_) => {
    if (!React.isValidElement(child_)) {
      return child_;
    }
    const child: React.ReactElement = child_;
    if (child.type === LayoutFooter) {
      rawFooter = child;
      return null;
    }
    if (child.type === LayoutHeader) {
      rawHeader = child;
      return null;
    }

    return child;
  });

  // console.log(children);

  return (
    <div className={classNames('layout', {
      'layout--no-scroll': noScroll,
      'layout--bg-white': isBgWhite
    })}>
      {!header && rawHeader}
      {header && <LayoutHeader isWhite={isHeaderWhite}>{header}</LayoutHeader>}
      <LayoutMain>
        <LayoutBody className={className}>{children}</LayoutBody>
        {!footer && rawFooter}
        {footer && <LayoutFooter>{footer}</LayoutFooter>}
      </LayoutMain>
    </div>
  );
};

const LayoutMain: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="layout__main">
      {children}
    </div>
  );
};

const LayoutBody: FC<{
  children: ReactNode,
  className?: string
}> = ({
  children,
  className
}) => {
  return (
    <div className="layout__body">
      <div className={classNames('layout__body-inner', className)}>
        {children}
      </div>
    </div>
  );
};

const LayoutHeader: FC<{
  isWhite?: boolean
  className?: string
  children: ReactNode
}> = ({
  isWhite,
  children,
  className
}) => {
  return (
    <div className={classNames('layout__header', { 'layout__header--bg-white': isWhite })}>
      <div className={classNames('layout__inner', className)}>{children}</div>
    </div>
  );
};

const LayoutFooter: FC<{
  className?: string
  children: ReactNode
}> = ({
  className,
  children
}) => {
  return (
    <div className="layout__footer">
      <div className={classNames('layout__inner', className)}>
        {children}
      </div>
    </div>
  );
};

const LayoutRow: FC<{
  children: ReactNode,
  className?: string
}> = ({
  children,
  className
}) => (
  <div className={classNames('layout__row', className)}>{children}</div>
);

Layout.Header = LayoutHeader;
Layout.Row = LayoutRow;
Layout.Footer = LayoutFooter;

export default Layout;
