import React, { FC } from 'react';
import { COLOR_PRIMARY } from 'const';

const IconArrow: FC<React.SVGProps<SVGSVGElement>> = ({ color = COLOR_PRIMARY, className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g>
        <path d="M19 12H5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 19L5 12L12 5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};

export default IconArrow;
