import React, { FC, SVGProps } from 'react';

const IconCheck: FC<SVGProps<SVGSVGElement> & { size?: 'small' | 'medium' | 'large' }> = ({
  color = '#fff',
  width = 24,
  height = 24,
  size = 'large'
}) => {
  if (size == 'medium') {
    return (
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3337 2L5.00033 10L1.66699 6.36364" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  }
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 6L9 17L4 12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default IconCheck;
