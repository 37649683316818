import classNames from 'classnames';
import React, { FC } from 'react';

import { COLOR_SECONDARY, COLOR_SECONDARY_LINEAR } from 'const';

import './RadialProgressBar.less';

// https://blog.logrocket.com/build-svg-circular-progress-component-react-hooks/
const RadialProgressBar: FC<{
  size?: number
  strokeWidth?: number
  progress: number
  className?: string
  highlight?: number
  children?: React.ReactNode
}> = ({
  size = 56,
  strokeWidth = 4,
  progress,
  className,
  children,
  highlight = 0
}) => {
  if (progress > 100) {
    progress = 100;
  }

  const center = size / 2;
  const radius = center - strokeWidth / 2;
  const arcLength = 2 * Math.PI * radius;
  const arcOffset = arcLength * ((100 - progress) / 100) ;

  return (
    <div
      style={{ width: `${size}px`, height: `${size}px` }}
      className={classNames(
        'radial-progress-bar',
        className,
        { 'radial-progress-bar--highlight-1': highlight === 1 }
      )}
    >
      <svg
        className="radial-progress-bar__svg"
        width={size}
        height={size}
      >
        <linearGradient
          id="linear-gradient"
          gradientUnits="userSpaceOnUse"
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="20%" stopColor={COLOR_SECONDARY_LINEAR} stopOpacity="100%" />
          <stop offset="79%" stopColor={COLOR_SECONDARY} stopOpacity="100%" />
        </linearGradient>
        <circle
          className="radial-progress-bar__scale"
          strokeWidth={strokeWidth}
          cx={center}
          cy={center}
          r={radius}
        />
        <circle
          style={{ stroke: 'url(#linear-gradient)' }}
          className="radial-progress-bar__scale-fill"
          strokeWidth={strokeWidth}
          strokeDasharray={arcLength}
          strokeDashoffset={arcOffset}
          cx={center}
          cy={center}
          r={radius}
        />
      </svg>
      {children && <div className="radial-progress-bar__content">{children}</div>}
    </div>
  );
};

export default RadialProgressBar;
