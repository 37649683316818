import { useLocalStorage } from 'hooks';
import type { Word } from 'client/types';
import { calcProgressPercent } from 'utils';
import {
  WORDS_EXERCISE_REPEATED_COUNT_COMPLETED,
  STORAGE_WORDS_REPEATED_COUNT_BY_ID,
  STORAGE_WORDS_EXCLUDED_COUNT_BY_ID,
  STORAGE_CONFIG
} from 'const';

export type WordWithProgress = Word & {
  progress: number
  repeatedCount: number
  excludedCount: number
}

export const useWordsProgress = (words: Word[]) => {
  const [repeatedCountById, setRepeatedCountById] = useLocalStorage<{ [Key: string]: number }>(STORAGE_WORDS_REPEATED_COUNT_BY_ID, {});
  const [excludedCountById, setExcludedCountById] = useLocalStorage<{ [Key: string]: number }>(STORAGE_WORDS_EXCLUDED_COUNT_BY_ID, {});

  const incrementWordRepeatedCount = (wordId: number) => {
    setRepeatedCountById(({
      ...repeatedCountById,
      [String(wordId)]: (repeatedCountById[String(wordId)] || 0) + 1
    }));
  };

  const incrementWordExcludedCount = (wordId: number) => {
    setExcludedCountById(({
      ...excludedCountById,
      [String(wordId)]: (excludedCountById[String(wordId)] || 0) + 1
    }));
  };

  const incrementWordsRepeatedCount = (wordIds: number[]) => {
    const _repeatedCountById = { ...repeatedCountById };
    for (const wordId of wordIds) {
      _repeatedCountById[String(wordId)] = (repeatedCountById[String(wordId)] || 0) + 1;
    }
    setRepeatedCountById(_repeatedCountById);
  };

  return {
    incrementWordRepeatedCount,
    incrementWordsRepeatedCount,
    incrementWordExcludedCount,
    words: words.map(word => ({
      ...word,
      excludedCount: excludedCountById[String(word.id)] || 0,
      repeatedCount: repeatedCountById[String(word.id)] || 0,
      progress: calcProgressPercent(
        repeatedCountById[String(word.id)] || 0,
        WORDS_EXERCISE_REPEATED_COUNT_COMPLETED
      )
    })),
  };
};

export type Config = {
  wordsToLearnCount: number;
}

export const useConfig = () => {
  const [config, setConfig] = useLocalStorage<Config>(STORAGE_CONFIG, {
    wordsToLearnCount: 7
  });

  const setMaxWordsToLearn = () => {
    setConfig({
      ...config,
      wordsToLearnCount: 7
    });
  };

  const setMinWordsToLearn = () => {
    setConfig({
      ...config,
      wordsToLearnCount: 4
    });
  };

  return {
    config,
    actions: {
      setMaxWordsToLearn,
      setMinWordsToLearn
    }
  };
};
