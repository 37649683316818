import axios from 'axios';
import { useEffect, useState } from 'react';
import urls from './urls';
import { getAuthHeader } from 'authentication';

import type * as types from './types';

export const useFetchInitData = () => {
  const {
    isLoading,
    error,
    entity: initData
  } = useFetchEntity<types.Init2Data>('/api/init2/');
  return { isLoading, error, initData };
};

export const useFetchVocabulary = () => {
  const {
    isLoading,
    error,
    entity: vocabulary
  } = useFetchEntity<types.Vocabulary>('/api/vocabulary/');
  return { isLoading, error, vocabulary };
};

export const useFetchCurrentUser = () => {
  const {
    isLoading,
    error,
    entity: user
  } = useFetchEntity<types.User>(urls.user());
  return { isLoading, error, user };
};

export const useFetchTopics = () => {
  const {
    isLoading,
    error,
    entity: topics
  } = useFetchEntity<types.Topic[]>('/api/topics/');
  return { isLoading, error, topics };
};

export const useFetchSubtopic = (subtopicId: number) => {
  const {
    isLoading,
    error,
    entity: subtopic
  } = useFetchEntity<Omit<types.Subtopic, 'relatedWords'> & { relatedWords: types.Word[] }>(`/api/subtopics/${subtopicId}/`);
  return { isLoading, error, subtopic };
};

export const useFetchSubtopicGrammarQuiz = (subtopicId: number, quizType: string) => {
  const {
    isLoading,
    error,
    entity: grammarQuiz
  } = useFetchEntity<types.GrammarQuiz>(
    `/api/subtopics/${subtopicId}/grammar-quizes/${quizType}/`
  );
  return { isLoading, error, grammarQuiz };
};

const useFetchEntity = <T>(url: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>(null);
  const [entity, setEntity] = useState<T>(null);

  useEffect(() => {
    sendRequest<T>({
      url: url,
      method: 'get'
    }).then(response => {
      setEntity(response);
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
      setError(err);
    });
  }, [url]);

  return {
    isLoading,
    error,
    entity
  };
};

async function sendRequest<T>(
  params: {
    url: string
    method: string
    data?: { [Key: string]: any }
    params?: { [Key: string]: any }
  }
): Promise<T> {
  const send = async () => (await axios<T>({
    ...params,
    headers: getAuthHeader()
  })).data;

  try {
    return await send();
  } catch (error) {
    // if (error.response.status === 401) {
    //   const isRefreshed = await refreshToken();
    //   if (isRefreshed) {
    //     return await send();
    //   }
    // }
    console.error(error);
    throw error;
  }
}
