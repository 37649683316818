import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import './Button.less';

type Props = {
  color?: 'primary' | 'secondary' | 'default'
  size?: 'small' | 'normal'
  // component?: React.ComponentType
  type?: 'button' | 'reset' | 'submit'
  isSmall?: boolean
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  transparent?: boolean
  isWide?: boolean
  className?: string
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const Button: FC<Props> = ({
  color = 'default',
  size = 'normal',
  primary,
  secondary,
  disabled,
  transparent,
  children,
  isWide,
  className,
  isSmall,
  type,
  onClick,
}) => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (onClick && !disabled) {
      onClick(event);
    }
  }, [disabled, onClick]);

  return (
    <button
      className={classNames(
        'button',
        `button--${color}`,
        `button--size-${size}`,
        className,
        {
          'button--primary': primary,
          'button--disabled': disabled,
          'button--secondary': secondary,
          'button--full-width': isWide,
          'button--transparent': transparent,
          'button--size-small': isSmall,
        }
      )}
      type={type || 'button'}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
