import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useFetchSubtopic } from 'client/hooks';
import urls, { reverse } from 'v2/urls';

import Label from 'components/Label';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import Topbar from 'components/Topbar';

import 'components/screens/Subtopic/Subtopic.less';
import { GRAMMAR_QUIZ_TYPE_SIMPLE_NUMBERS } from 'const';

const Subtopic: FC = () => {
  const { subtopicId } = useParams();
  const navigate = useNavigate();

  const { isLoading, subtopic } = useFetchSubtopic(Number(subtopicId));

  if (isLoading) {
    return <Loading />;
  }

  const { relatedWords, grammarQuizes } = subtopic;
  const hasGrammarQuizes = grammarQuizes.length > 0 || subtopic.grammarQuizTypes.includes(GRAMMAR_QUIZ_TYPE_SIMPLE_NUMBERS);

  return (
    <Layout className="subtopic">
      <Layout.Header isWhite>
        <Topbar
          title={`${subtopic.emoji} ${subtopic.title}`}
          backLink="/"
        />
      </Layout.Header>
      <Layout.Row>
        {relatedWords.length > 0 && <div
          className="subtopic-words-widget paper"
          onClick={() => navigate(reverse(urls.subtopicWords, { subtopicId }))}
        >
          <div className="subtopic-words-widget__icon">📖</div>
          <div className="subtopic-words-widget__title">
            Подборка слов <span>{relatedWords.length}</span>
          </div>
          <div className="subtopic-words-widget__arrow" />
        </div>}
        {hasGrammarQuizes && <Label.Box className="subtopic__subtitle">
          <Label highlight={1}>Грамматика</Label>
        </Label.Box>}
        {subtopic.grammarQuizTypes.includes(GRAMMAR_QUIZ_TYPE_SIMPLE_NUMBERS) && <div
          className="subtopic-grammar-exercise subtopic-words-widget paper"
          onClick={() => navigate(reverse(urls.numbersExercise, { subtopicId }))}
        >
          <div className="subtopic-words-widget__icon">🔢</div>
          <div className="subtopic-words-widget__title">Числа</div>
          <div className="subtopic-words-widget__arrow" />
        </div>}
        {grammarQuizes.map((quiz, index) => (
          <div
            key={index}
            className="subtopic-grammar-exercise subtopic-words-widget paper"
            onClick={() => navigate(reverse(
              urls.subtopicGrammarExercise,
              { subtopicId, quizType: quiz.quizType })
            )}
          >
            <div className="subtopic-words-widget__icon">🤓</div>
            <div className="subtopic-words-widget__title">{quiz.quizType}</div>
            <div className="subtopic-words-widget__arrow" />
          </div>
        ))}
      </Layout.Row>
    </Layout>
  );
};

export default Subtopic;
