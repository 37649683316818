import React, { FC } from 'react';
import classNames from 'classnames';

import './ButtonGroup.less';

const ButtonGroup: FC<{
  className?: string
  children: React.ReactNode
  direction: 'row' | 'col'
}> = ({
  className,
  children,
  direction
}) => {
  return (
    <div className={classNames(`button-group button-group--direction-${direction}`, className)}>{children}</div>
  );
};

export default ButtonGroup;
