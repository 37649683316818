import React, { FC } from 'react';

import Layout from 'components/Layout';
import Button from 'components/Button';

import { getSocialAuthUrl } from 'authentication';
import { SOCIAL_AUTH_BACKEND_GOOGLE, SOCIAL_AUTH_BACKEND_INSTAGRAM } from 'const';

import './Login.less';

const Login: FC = () => {
  const handleLoginButtonClick = (socialAuthBackend: string) => () => {
    getSocialAuthUrl(socialAuthBackend).then(response => {
      window.location.href = response.url;
    });
  };

  return (
    <Layout className="login" noScroll>
      <div className="login__inner">
        <div />
        <div className="login__logo-block">
          <div className="login__logo" />
          <div className="login__slogan">Изучай языки с нами!</div>
        </div>
        <div className="login__buttons">
          <div className="login__buttons-title">Войти через</div>
          {/* <Button
            className="login__button login__button--fb"
            onClick={handleLoginButtonClick(SOCIAL_AUTH_BACKEND_INSTAGRAM)}
          >Instagram</Button> */}
          {/* <Button className="login__button login__button--vk">Вконтакте</Button> */}
          <Button
            className="login__button login__button--google"
            onClick={handleLoginButtonClick(SOCIAL_AUTH_BACKEND_GOOGLE)}
          >Google</Button>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
