import React, { FC } from 'react';
import classNames from 'classnames';

import RadialProgressBar from 'components/RadialProgressBar';
import { IconCheck } from 'components/svg';

import './RadialProgressState.less';

const RadialProgressState: FC<{
  className?: string
  size?: 'small' | 'normal'
  progress?: number
  isLocked?: boolean
  notStartedStyle?: 'arrow' | 'dot'
}> = ({
  isLocked,
  progress,
  className,
  size = 'normal',
  notStartedStyle = 'dot'
}) => {
  const isCompleted = progress >= 100;
  const isNotStarted = progress === 0;
  const isSmall = size == 'small';

  return (
    <div className={classNames(
      'radial-progress-state',
      `radial-progress-state--size-${size}`,
      className,
      {
        'radial-progress-state--locked': isLocked,
        'radial-progress-state--completed': isCompleted,
        [`radial-progress-state--not-started-style-${notStartedStyle}`]: isNotStarted
      }
    )}>
      {!isLocked && !isCompleted && <RadialProgressBar
        highlight={1}
        progress={progress || 0}
        size={isSmall ? 24 : 32}
        strokeWidth={isSmall ? 4 : 6}
      >
        {isNotStarted && <div className="radial-progress-state__not-started-icon" />}
      </RadialProgressBar>}
      {isCompleted && <IconCheck size="medium" />}
      {isLocked && <div className="radial-progress-state__icon-lock" />}
    </div>
  );
};

export default RadialProgressState;
