import React, { useEffect } from 'react';

import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useApp } from 'v2/contexts/AppContext';

import AdminLogin from 'components/screens/AdminLogin';
import Login from 'components/screens/Login';
import Course from './routes/Course';
import GrammarQuiz from './routes/GrammarQuiz';
import { SimpleProfile } from 'components/screens/Profile';
import SocialAuth from './routes/SocialAuth';
import Subtopic from './routes/Subtopic';
import SubtopicWords from './routes/SubtopicWords';

import urls from 'v2/urls';

import 'styles/fonts.less';
import NumbersQuiz from './routes/NumbersQuiz/NumbersQuiz';

const anonymousRouter = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: urls.socialAuth,
    element: <SocialAuth />
  },
  {
    path: '/admin-login/',
    element: <AdminLogin />
  },
  {
    path: '*',
    element: <Navigate to="/" />
  }
]);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Course />
  },
  {
    path: urls.subtopic,
    element: <Subtopic />
  },
  {
    path: urls.subtopicGrammarExercise,
    element: <GrammarQuiz />
  },
  {
    path: urls.subtopicWords,
    element: <SubtopicWords />
  },
  {
    path: urls.profile,
    element: <SimpleProfile />
  },
  {
    path: urls.numbersExercise,
    element: <NumbersQuiz />
  },
  {
    path: '*',
    element: <Navigate to="/" />
  }
]);

const App = () => {
  const { isLoading, isAuthenticated } = useApp();

  useEffect(() => {
    if (!isLoading) {
      const elem = document.querySelector('#app');
      elem.classList.remove('splash-screen');
    }
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  return <RouterProvider router={isAuthenticated ? router : anonymousRouter} />;
};

export default App;
