import classNames from 'classnames';
import React, { FC } from 'react';

import Layout from 'components/Layout';
import Navbar from 'components/Navbar';
import { CourseTopicSubtopic } from 'components/screens/Course/Course';
import { useApp } from 'v2/contexts/AppContext';

const Course: FC = () => {
  const { topics } = useApp();

  return (
    <Layout className="course">
      {/* <Layout.Header isWhite>
        <CourseBlock />
      </Layout.Header> */}
      <Layout.Row>
        {/* <div className="course__subtitle"><Label>Темы</Label></div> */}
        {topics.map(topic => (
          <div
            key={topic.id}
            className={classNames('course-topic paper')}
          >
            <div className="course-topic__head">
              <div className="course-topic__title h1">{topic.title}</div>
              {/* {topic.isLocked ?
                <IconLoc color={COLOR_NEUTRAL_500} /> :
                <Label className="course-topic__progress">{topic.progress}%</Label>
              } */}
            </div>
            <div className="course-topic__subtopics">
              {topic.subtopics.map((subtopic, idx) => (
                <CourseTopicSubtopic
                  key={subtopic.id}
                  id={subtopic.id}
                  progress={0}
                  emoji={subtopic.emoji}
                  title={subtopic.title}
                  noFloat={idx === 0 && topic.subtopics.length > 3}
                />
              ))}
            </div>
          </div>
        ))}
      </Layout.Row>
      <Layout.Footer>
        <Layout.Row>
          <Navbar isVocabularyShown={false} />
        </Layout.Row>
      </Layout.Footer>
    </Layout>
  );
};

export default Course;
