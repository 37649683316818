import _ from 'lodash';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchSubtopic } from 'client/hooks';
import type { Word, Subtopic } from 'client/types';

import Button from 'components/Button';
import Label from 'components/Label';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import Topbar from 'components/Topbar';
import VocabularyWord from 'components/VocabularyWord';
import PhrasesExercise from 'components/screens/PhrasesExercise';

import { useApp } from 'v2/contexts/AppContext';
import urls, { reverse } from 'v2/urls';
import WordsToLearnModal from 'v2/modals/WordsToLearnModal';
import { useWordsProgress, WordWithProgress } from 'v2/hooks';

type _Subtopic = Omit<Subtopic, 'relatedWords'> & { relatedWords: Word[] }

const SubtopicWordsOrLoader: FC = () => {
  const { subtopicId } = useParams<{ subtopicId: string }>();
  const { isLoading, subtopic } = useFetchSubtopic(Number(subtopicId));

  if (isLoading) {
    return <Loading />;
  }

  return <SubtopicWords subtopic={subtopic} />;
};

const SubtopicWords: FC<{ subtopic: _Subtopic }> = ({ subtopic }) => {
  const { subtopicId } = useParams<{ subtopicId: string }>();
  const [isLearnWordsModalOpen, setIsLearnWordsModalOpen] = useState(false);
  const [isExerciseRun, setIsExerciseRun] = useState(false);
  const [wordsToLearn, setWordsToLearn] = useState<Word[]>([]);

  const { vocabulary } = useApp();

  const {
    words,
    incrementWordsRepeatedCount,
    incrementWordExcludedCount
  } = useWordsProgress(subtopic.relatedWords);
  const categoriesById = _.keyBy(vocabulary.categories, c => c.id);
  const categoriesIds = _.uniq(words.reduce((acc, word) => ([...acc, ...word.categories]), []));
  const categories = categoriesIds.map(cid => ({
    ...categoriesById[cid],
    words: words.filter(w => w.categories.includes(cid))
  }));

  const handleLearnModalCloseClick = () => {
    setIsLearnWordsModalOpen(false);
  };

  const handleLearnButtonClick = () => {
    setWordsToLearn(findWordsToLearn(words));
    setIsLearnWordsModalOpen(true);
  };

  const handleExerciseStartClick = (_words: Word[]) => {
    setWordsToLearn(_words);
    setIsExerciseRun(true);
    setIsLearnWordsModalOpen(false);
  };

  const handleExcludeWord = (wordId: number) => {
    incrementWordExcludedCount(wordId);
  };

  const handleExerciseFinish = () => {
    incrementWordsRepeatedCount(wordsToLearn.map(w => w.id));
  };

  const handleCategoryChange = (categoryId: number) => {
    setWordsToLearn(findWordsToLearn(words, { choosenCategoryId: categoryId }));
  };

  const handleNextExerciseClick = () => {
    setIsExerciseRun(false);
    handleLearnButtonClick();
  };

  if (isExerciseRun) {
    return <PhrasesExercise
      exerciseName="Слова"
      title={subtopic.title}
      isWords
      phrases={wordsToLearn}
      onBackClick={() => setIsExerciseRun(false)}
      onExitConfirmClick={() => setIsExerciseRun(false)}
      onNextExerciseClick={handleNextExerciseClick}
      // onStart={handleStart}
      onFinish={handleExerciseFinish}
    />;
  }

  return (
    <Layout className="subtopic-vocabulary">
      {isLearnWordsModalOpen && <WordsToLearnModal
        title="Учить слова"
        choosenCategoryId={wordsToLearn[0]?.categories[0]}
        categories={categories}
        words={wordsToLearn}
        onCloseClick={handleLearnModalCloseClick}
        onStartClick={handleExerciseStartClick}
        onWordRemoved={handleExcludeWord}
        onCategoryChange={handleCategoryChange}
      />}
      <Layout.Header isWhite>
        <Topbar
          title={`${subtopic.emoji} ${subtopic.title}`}
          backLink={reverse(urls.subtopic, { subtopicId })}
        />
      </Layout.Header>
      <Layout.Row className="subtopic-vocabulary__inner">
        {categories.map(category => (
          <React.Fragment key={category.id}>
            {categories.length > 1 && <Label.Box>
              <Label highlight={1}>{category.title}</Label>
            </Label.Box>}
            <div className="paper">
              {category.words.map(word => (
                <VocabularyWord
                  key={word.id}
                  text={word.text}
                  textAudio={word.textAudio}
                  translation={word.textTranslation}
                  progress={word.progress}
                />
              ))}
            </div>
          </React.Fragment>
        ))}
      </Layout.Row>
      <Layout.Footer>
        <Layout.Row>
          <Button
            color="secondary"
            isWide
            onClick={handleLearnButtonClick}
          >Учить слова</Button>
        </Layout.Row>
      </Layout.Footer>
    </Layout>
  );
};

export const findWordsToLearn = (words: WordWithProgress[], options?: { choosenCategoryId?: number, limit?: number }) => {
  const cateogriesIds = _.uniq(words.reduce((acc, word) => ([...acc, ...word.categories]), []));
  if (!cateogriesIds.length) {
    return [];
  }
  const categoryId = options?.choosenCategoryId ? options.choosenCategoryId : _.sample(cateogriesIds);
  const categoryWords = _.shuffle(words.filter(w => w.categories.includes(categoryId)));
  const wordsToLearn: WordWithProgress[] = _.sortBy(categoryWords, [w => w.repeatedCount + w.excludedCount]);
  return wordsToLearn.slice(0, options?.limit);
};

export default SubtopicWordsOrLoader;
