export const TASK_TYPE_READING = 'TASK_TYPE_READING';
export const TASK_TYPE_FOREIGN_TO_NATIVE = 'TASK_TYPE_FOREIGN_TO_NATIVE';
export const TASK_TYPE_NATIVE_TO_FOREIGN = 'TASK_TYPE_NATIVE_TO_FOREIGN';
export const TASK_TYPE_EXAMPLES = 'TASK_TYPE_EXAMPLES';
export const TASK_TYPE_LISTENING = 'TASK_TYPE_LISTENING';
export const TASK_TYPE_RECORDING = 'TASK_TYPE_RECORDING';

export const EXAMPLES_READING = 'EXAMPLES_READING';
export const EXAMPLES_GAP_ENDING = 'EXAMPLES_GAP_ENDING';
export const EXAMPLES_GAP_ALL = 'EXAMPLES_GAP_ALL';

export const TASK_TYPES: TaskType[] = [
  TASK_TYPE_READING,
  TASK_TYPE_FOREIGN_TO_NATIVE,
  TASK_TYPE_NATIVE_TO_FOREIGN,
  TASK_TYPE_LISTENING,
  TASK_TYPE_RECORDING,
  TASK_TYPE_EXAMPLES,
];

export const EXAMPLES_TASK_SUBTYPES: ExamplesTaskSubtype[] = [
  EXAMPLES_READING,
  EXAMPLES_GAP_ENDING,
  EXAMPLES_GAP_ALL
];

export type TaskType =
  typeof TASK_TYPE_READING |
  typeof TASK_TYPE_FOREIGN_TO_NATIVE |
  typeof TASK_TYPE_NATIVE_TO_FOREIGN |
  typeof TASK_TYPE_EXAMPLES |
  typeof TASK_TYPE_LISTENING |
  typeof TASK_TYPE_RECORDING;

export type ExamplesTaskSubtype =
  typeof EXAMPLES_READING |
  typeof EXAMPLES_GAP_ENDING |
  typeof EXAMPLES_GAP_ALL;
