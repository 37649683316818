import React, { FC } from 'react';
import classNames from 'classnames';

// import { useAudio } from 'hooks';
import { capitalize } from 'utils';

import { IconSpeaker } from 'components/svg';
import RadialProgressState from 'components/RadialProgressState';

import { COLOR_PRIMARY, COLOR_NEUTRAL_500 } from 'const';

import './VocabularyWord.less';
import { useAudio } from 'v2/contexts/AudioContext';

const VocabularyWord: FC<{
  text: string
  textAudio?: string
  translation: string
  progress?: number
  isSelection?: boolean
  isSelected?: boolean
  isDisabled?: boolean
  onCheckboxClick?: () => void
  onRemove?: () => void
}> = ({
  text,
  textAudio,
  translation,
  progress,
  isSelection,
  isSelected,
  isDisabled,
  onCheckboxClick,
  onRemove
}) => {
  const [play] = useAudio(textAudio);
  const isRemovable = !!onRemove;

  const handleClick = () => {
    if (textAudio && !isSelection) {
      play();
    } else if (isSelection && !isDisabled) {
      onCheckboxClick();
    }
  };

  return (
    <div
      className={classNames(
        'vocabulary-word',
        {
          'vocabulary-word--playable': textAudio && !isSelection,
          'vocabulary-word--checkbox': isSelection,
          'vocabulary-word--checkbox-checked': isSelection && isSelected,
          'vocabulary-word--disabled': isDisabled
        })}
      onClick={handleClick}
    >
      {textAudio && <IconSpeaker className="vocabulary-word__play-button" color={COLOR_NEUTRAL_500} />}
      <div className="vocabulary-word__content">
        <div className="vocabulary-word__text">{capitalize(text)}</div>
        <div className="vocabulary-word__translation">{capitalize(translation)}</div>
      </div>
      {progress > 0 && <RadialProgressState
        className="vocabulary-word__progress"
        progress={progress}
        size='small'
      />}
      {isRemovable && <div
        className="vocabulary-word__remove"
        onClick={onRemove}
      />}
    </div>
  );
};

// const VocabularyWordProgress: FC<{
//   progress: number
//   className?: string
// }> = ({
//   progress,
//   className
// }) => {
//   const progressLevel = getProgressLevelByPercent(progress);
//   return (
//     <div className={classNames('vocabulary-word-progress', className)}>
//       <div className={classNames('vocabulary-word-progress__indicator', {
//         'vocabulary-word-progress__indicator--red': progressLevel == PROGRESS_LEVEL_LOW,
//         'vocabulary-word-progress__indicator--yellow': progressLevel == PROGRESS_LEVEL_MEDIUM,
//         'vocabulary-word-progress__indicator--green': progressLevel == PROGRESS_LEVEL_HIGH
//       })} />
//       <div className={classNames('vocabulary-word-progress__indicator', {
//         'vocabulary-word-progress__indicator--yellow': progressLevel == PROGRESS_LEVEL_MEDIUM,
//         'vocabulary-word-progress__indicator--green': progressLevel == PROGRESS_LEVEL_HIGH
//       })} />
//       <div className={classNames('vocabulary-word-progress__indicator', {
//         'vocabulary-word-progress__indicator--green': progressLevel == PROGRESS_LEVEL_HIGH
//       })} />
//     </div>
//   );
// };

export default VocabularyWord;
